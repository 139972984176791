/*

    font-family: 'Archivo', sans-serif;
    font-family: 'Yantramanav', sans-serif;

*/



@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,500;1,100;1,300;1,400&family=Yantramanav:wght@100;300;400;500;700;900&display=swap');
html,

body {
    overflow-x: hidden;
}

html {
    scroll-behavior: smooth;
}

body {
    color: #565969;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left!important;
    font-family: 'Archivo', sans-serif;
}

p {

    font-weight: 400;
    font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #0a1027;
    font-family: 'Yantramanav', sans-serif;
    font-style: normal;
    margin-bottom: 0;
    margin-top: 11px;
    line-height: 1.2;
    font-weight: 600;
    -webkit-transition: .5s;
    transition: .5s;
    font-size: 36px;
}

a {
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    text-decoration: none;
}

a {
    color: #43baff;
    text-decoration: none;
}

a:focus,
.btn:focus {
    text-decoration: none;
    outline: none;
}

a:focus,
a:hover {
    text-decoration: none;
    text-decoration: none;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit;
    -webkit-transition: .5s;
    transition: .5s;
    text-decoration: none;
}

h1 {
    font-size: 50px;
}

h2 {
    font-size: 35px;

}

h3 {
    font-size: 30px;
}

h4 {
    font-size: 24px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 18px;
}

.white h1,
.white h2,
.white h3,
.white h4,
.white h5,
.white h6 {
    color: #fff;
}

.white p {
    color: #fff;
}

.white i {
    color: #fff;
}

ul {
    margin: 0px;
    padding: 0px;
}



ul {
    margin: 0;

    padding: 0;
}



input:focus,
button:focus {
    border-color: #FF3C00;
    outline-color: #FF3C00;
    border: 1px solid #FF3C00;
}

textarea:focus {
    border-color: #F16722;
    outline-color: #F16722;
}

hr {
    margin: 60px 0;
    padding: 0px;
    border-bottom: 1px solid #eceff8;
    border-top: 0px;
}

hr.style-2 {
    border-bottom: 1px dashed #f10;
}

hr.mp-0 {
    margin: 0;
    border-bottom: 1px solid #eceff8;
}

hr.mtb-40 {
    margin: 40px 0;
    border-bottom: 1px solid #eceff8;
    border-top: 0px;
}

label {
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
}

a,
i,
cite,
em,
var,
address,
dfn,
ol,
li,
ul {
    font-style: normal;
    font-weight: 400;
}


*::-moz-selection {
    background: #4fc1f0;
    color: #454545;
    text-shadow: none;
}

::-moz-selection {
    background: #4fc1f0;
    color: #454545;
    text-shadow: none;
}

::selection {
    background: #4fc1f0;
    color: #454545;
    text-shadow: none;
}

::placeholder {
    color: #454545;
    font-size: 16px;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */

    color: #454545;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #454545;
}

:-ms-input-placeholder {
    /* IE 10+ */

    color: #454545;
}

:-moz-placeholder {
    /* Firefox 18- */

    color: #454545;
}

/* Contact Area */
.counter_area ::-moz-selection {
    color: #001d23;
    text-shadow: none;
}

.contact_area ::-moz-selection {
    color: #001d23;
    text-shadow: none;
}

.contact_area ::selection {
    color: #001d23;
    text-shadow: none;
}

.contact_area ::placeholder {
    color: #001d23;
    font-size: 16px;
}

.contact_area ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #001d23;
}

.contact_area ::-moz-placeholder {
    /* Firefox 19+ */
    color: #001d23;
}

.contact_area :-ms-input-placeholder {
    /* IE 10+ */
    color: #001d23;
}

.contact_area :-moz-placeholder {
    /* Firefox 18- */
    color: #001d23;
}

.blog_reply ::-moz-selection {
    color: #151948;
}

.blog_reply ::-moz-selection {
    color: #151948;
}

.blog_reply ::selection {
    color: #151948;
}

.blog_reply::placeholder {
    color: #151948;
}

.blog_reply ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #151948;
}

.blog_reply ::-moz-placeholder {
    /* Firefox 19+ */
    color: #151948;
}

.blog_reply :-ms-input-placeholder {
    /* IE 10+ */
    color: #151948;
}

.blog_reply :-moz-placeholder {
    /* Firefox 18- */
    color: #151948;
}
