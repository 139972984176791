/*================================
 <-- Consen Top Menu Area Css-->
==================================*/
.top-menu {
    background: #0A1028;
    padding: 10px 50px;
    position: relative;
    z-index: 1;
    margin: 0;
    border-bottom: 1px solid #22293C;
}

.top-menu::before {
    position: absolute;
    top: 0;
    left: -15px;
    height: 100%;
    width: 38%;
    background: #FF5E15;
    content: "";
    z-index: -1;
    transform: skew(-30deg, 0deg);
}

.top-menu-icon ul li {
    list-style: none;
    display: inline-block;
    margin-right: 35px;
    font-size: 15px;
    line-height: 30px;
    color: #ffffff;
    font-weight: 400;
}

.top-menu-right-site {
    text-align: right;
}

.top-menu-icon-list ul li.mgr {
    display: inline-block;
    margin-right: 35px;
    position: relative;
    z-index: 1;  
}

.top-menu-icon-list ul li.mgr::before {
    position: absolute;
    content: "";
    top: 5px;
    right: -20px;
    height: 17px;
    width: 1px;
    background: #F1F1F1;
    opacity: 0.5;
}

.top-menu-icon-list ul li {
    display: inline-block;
    list-style: none;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
}

.top-menu-icon-list ul li i {
    display: inline-block;
    margin-right: 10px;
}

.top-menu-icon-list ul li.text {
    padding: 0 20px 0 0;
    position: relative;
    z-index: 1;
}

.top-menu-icon-list ul li.text::before {
    position: absolute;
    content: "";
    top: 5px;
    right: 3px;
    height: 17px;
    width: 1px;
    background: #35363A;
}

.top-menu-icon-list ul li a:hover{
    color: #FE3D02;
}

.header-button a {
    display: inline-block;
    font-size: 16px;
    background: #FF5E15;
    text-transform: uppercase;
    padding: 12px 27px;
    color: #fff;
    position: relative;
    z-index: 1;
}

.header-button a::before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    transform: scale(0);
    transition: .5s;
}

.header-button a i {
    display: inline-block;
    font-size: 25px;
    transform: rotate(45deg);
    margin: 0 0 0;
    position: relative;
    bottom: -3px;
}

.sticky .header-button a:hover {
    color: #FF5E15;
}

.sticky .header-button a::before {
    background: #fff;
}

.sticky .hendre_nav_manu.style_two .header-button a::before {
    background: red;
}

.header-button a:hover::before {
    transform: scale(1);
}

.header-button a:hover {
    color: #FF5E15;
}

.header-button.upper a {
    display: inline-block;
    font-size: 16px;
    background: #E11143;
    text-transform: uppercase;
    padding: 12px 33px;
    color: #fff;
    border-radius: 5px;
    position: relative;
    z-index: 1;
}

.top-menu-icon-list {
    display: inline-block;
}

.country_icon {
    text-align: right;
    display: inline-block;
    margin-right: 50px;
}

.country_icon i::after {
    position: absolute;
    content: "";
    top: 0;
    right: -25px;
    height: 20px;
    width: 1px;
    background: #A8A8A8;
    opacity: 0.5;
}

.top-menu.style_two {
    background: #fff;
    border-bottom: 1px solid #F1F1F1;
}

.top-menu.style_two .top-menu-icon ul li {
    list-style: none;
    display: inline-block;
    margin-right: 35px;
    line-height: 44px;
    color: #565969;
    font-weight: 400;
    font-family: "Yantramanav";
    font-size: 16px;
}

.top-menu.style_two .top-menu-icon ul li i {
    display: inline-block;
    color: #FF5D13;
    margin-right: 6px;
    font-weight: 800;
    font-family: "Yantramanav";
}

.top-menu.style_two::before {
    background: inherit;
}

.top-menu.style_two .top-menu-icon-list ul li a {
    display: inline-block;
    font-size: 16px;
    color: #0A1026;
    transition: .5s;
}

.top-menu.style_two .top-menu-icon-list ul li i {
    display: inline-block;
    margin-right: 17px;
    font-weight: 900;
}

.top-menu.style_two .top-menu-icon-list ul li a:hover {
    color: #FF5D13;
}

.hendre_nav_manu.style_two {
    background: #fff!important;
    padding: 10px 50px;
}

.hendre_nav_manu.style_two .header-button a::before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #0A1027;
    transform: scale(0);
    transition: .5s;
}

.hendre_nav_manu.style_two .header-button a:hover::before{
    background: #0A1027;
    transform: scale(1);
}

.hendre_nav_manu.style_two .header-button a:hover{
    color: #fff;
}

.sticky .hendre_nav_manu.style_two {
    background: #fff!important;
    margin: 0 50px 0;
}

.hendre_nav_manu.style_two .hendre_menu>ul>li>a {
    color: #0B1028;
}

.hendre_nav_manu.style_two .hendre_menu>ul>li>a:hover {
    color: #FF5D13;
}

/*================================
<--  hendre Nav Menu Area Css -->
==================================*/

.hendre_nav_manu {
    transition: .5s;
    z-index: 2;
    position: relative;
    padding: 10px 0px;
    background: #0A1028;
}

.sticky.hendre_nav_manu {
    border-bottom: inherit;
}

.hendre_nav_manu.style-two {
    transition: .5s;
    z-index: 2;
    position: relative;
    padding: 0 100px;
    background: #fff;
}

.hendre_nav_manu.style-two .search-box-btn.search-box-outer i {
    color: #333;
}

.sticky.hendre_nav_manu.style-two .search-box-btn.search-box-outer i {
    color: #fff;
}

.hendre_nav_manu.style-two .hendre_menu>ul>li>a {
    color: #333;
}

.sticky.hendre_nav_manu.style-two .hendre_menu>ul>li>a {
    color: #fff!important;
}

.hendre_nav_manu.transparent_menu {
    background: transparent;
    margin-bottom: -87px;
    position: relative;
}

.sticky {
    left: 0;
    margin: auto;
    position: fixed !important;
    top: 0;
    width: 100%;
    -webkit-box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.1);
    background: #0A1028!important;
    transition: .5s;
    z-index: 9;
    animation: 500ms running fadeInUp;
    animation-name: slideInDown;
}

.header-icon {
    display: inline-block;
    margin: 0 25px 0 15px;
}

.header-icon a {
    display: inline-block;
    position: relative;
    z-index: 1;
    color: #012549;
    font-size: 20px;
}

.sticky .header-icon a {
    color: #fff;

}

.header-icon a::after {
    position: absolute;
    content: "0";
    top: 0;
    right: -8px;
    height: 15px;
    width: 15px;
    line-height: 15px;
    background: #E01142;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
}

.header-button {
    display: inline-block;
}

/* hendre Menu Css*/
nav.hendre_menu {
    text-align: right;
}

.hendre_menu ul {
    list-style: none;
    display: inline-block;
}

.hendre_menu>ul>li {
    display: inline-block;
    position: relative;
    z-index: 1;
}

.hendre_menu>ul>li>a {
    display: block;
    margin: 40px 17px;
    -webkit-transition: .5s;
    transition: .5s;
    font-size: 16px;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    font-family: "Yantramanav";
}

nav.hendre_menu span {
    font-size: 13px;
    padding-left: 6px;
    font-family: FontAwesome;
}

.hendre_menu>ul>li>a:hover {
    color: #FF5E15;
}


/*** Sub Menu Style ***/
.hendre_menu ul .sub-menu {
    position: absolute;
    left: 0;
    top: 130%;
    width: 217px;
    text-align: left;
    background: #fff;
    margin: 0;
    z-index: 1;
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    transition: .5s;
    visibility: hidden;
    opacity: 0;
}

.hendre_menu ul li:hover>.sub-menu {
    visibility: visible;
    top: 100%;
    opacity: 1;
}

.hendre_menu ul .sub-menu li {
    position: relative;
}

.hendre_menu ul .sub-menu li a {
    display: block;
    padding: 12px 20px;
    margin: 0;
    line-height: 1.3;
    letter-spacing: normal;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    -webkit-transition: .1s;
    transition: .1s;
    visibility: inherit !important;
    color: #211e3b !important;
    transition: .5s;
}

.hendre_menu ul .sub-menu li:hover>a, .hendre_menu ul .sub-menu .sub-menu li:hover>a, .hendre_menu ul .sub-menu .sub-menu .sub-menu li:hover>a, .hendre_menu ul .sub-menu .sub-menu .sub-menu .sub-menu li:hover>a {
    background: #FF5D13;
    color: #fff !important;
}

/* sub menu 2 */
.hendre_menu ul .sub-menu .sub-menu {
    left: 100%;
    top: 130%;
    opacity: 0;
    visibility: hidden;
}

.hendre_menu ul .sub-menu li:hover>.sub-menu {
    opacity: 1;
    visibility: visible;
    top: 0%;
}

/* sub menu 3 */
.hendre_menu ul .sub-menu .sub-menu li {
    position: relative;
}

.hendre_menu ul .sub-menu .sub-menu .sub-menu {
    right: 100%;
    left: auto;
    top: 130%;
    opacity: 0;
    visibility: hidden;
}

.hendre_menu ul .sub-menu .sub-menu li:hover>.sub-menu {
    opacity: 1;
    visibility: visible;
    top: 0%;
}

ul.sub-menu li a span {
    background: #FF3C00;
    padding: 5px 10px;
    color: #fff;
    opacity: 1;
    font-family: 'Fira Sans';
    border-radius: 2px;
    margin-left: 5px;
    transition: .5s;
}

ul.sub-menu li:hover a span{
    background: rgba(255,255,255, 0.20);
}

/* sub menu 4 */
.hendre_menu ul .sub-menu .sub-menu .sub-menu li {
    position: relative;
}


.hendre_menu ul .sub-menu .sub-menu .sub-menu li:hover>.sub-menu {
    opacity: 1;
    visibility: visible;
    top: 0%;
}

.hendre_menu li a:hover::before {
    width: 101%;
}

.hendre_nav_manu.sticky .logo_img {
    display: none;
}

.main_sticky {
    display: none;
}

.hendre_nav_manu.sticky .main_sticky {
    display: inherit;
}

.sticky .hendre_menu li a {
    color: #fff;
}

.hendre_nav_manu.sticky a.dtbtn {
    color: #fff;
    background: #F16722;
    border: 2px solid #F16722;
}

.hendre_nav_manu.sticky a.dtbtn:hover {
    color: #43baff;
}

.mobile-menu.mean-container {
    overflow: hidden;
}

/*--==============================================->
<!-- Constre Breadcumb Area Css -->
==================================================-*/
.breadcumb-area {
    background: url(../../images/breadcumb.jpg);
    height: 500px;
    position: relative;
}

.breadcumb-content h1 {
    margin-bottom: 10px;
    font-size: 56px;
    color: #ffffff;
    font-weight: 700;
}

.breadcumb-content ul li {
    text-transform: uppercase;
    display: inline-block;
    font-size: 16px;
    color: #ffffff;
    font-weight: 400;
    margin-right: 6px;
}

.breadcumb-content ul li i {
    color: #fff;
    font-size: 15px;
    margin-right: 10px;
}

.breadcumb-content ul li a {
    text-transform: uppercase;
    padding-right: 30px;
    position: relative;
    z-index: 1;
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
}

.breadcumb-content ul li a::before {
    position: absolute;
    content: "";
    right: 8px;
    top: 3px;
    background: #fff;
    width: 2px;
    height: 12px;
    transition: .5s;
    transform: rotate(17deg);
}



/*<!-- ============================================================== -->
<!-- Start section-title Css -->
<!-- ============================================================== -->*/

.section-title {
    text-align: left;
    margin-bottom: 50px;
}

.section-title h4 {
    font-size: 16px;
    line-height: 30px;
    color: #ff5e14;
    font-weight: 500;
    margin: 0 0 0;
}

.section-title h1 {
    margin: 0 0 0;
    font-size: 49px;
    line-height: 58px;
    color: #0a1027;
    font-weight: 700;
}

p.section-description {
    font-size: 16px;
    width: 45%;
    margin: 0 auto 0;
}



/*<!-- ============================================================== -->
<!-- Start Slider Area Css -->
<!-- ============================================================== -->*/

.slider-area {
    /*background: url(../../images/slider-bg.jpg);*/
    /*background-size: cover;*/
    background-position: center;
    background-repeat: no-repeat;
    height: 770px;
}

.slider-area .slider-content .slider-title h4 {
    font-size: 25px;
    line-height: 44px;
    color: #ff5e14;
    font-weight: 500;
    margin: 0 0 0;
}

.slider-area .slider-content .slider-title h1 {
    font-size: 70px;
    line-height: 90px;
    color: #0a1027;
    font-weight: 700;
    margin: 15px 0 0;
}

.slider-area .slider-content .slider-title h2 {
    font-size: 70px;
    line-height: 80px;
    color: #0a1027;
    font-weight: 300;
    margin: 0 0 0;
}

.slider-area .slider-content p {
    font-size: 17px;
    line-height: 30px;
    color: #565969;
    font-weight: 400;
    margin: 20px 0 0;
    width: 55%;
}

.slider-area .slider-button {
    margin-top: 42px;
}

.slider-area .slider-button a {
    display: inline-block;
    font-size: 16px;
    background: #FF5E15;
    text-transform: uppercase;
    padding: 12px 25px;
    color: #fff;
    position: relative;
    z-index: 1;
}

.slider-area .slider-button a::before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #0A1028;
    transform: scale(0);
    transition: .5s;
}

.slider-area .slider-button a i {
    display: inline-block;
    font-size: 25px;
    transform: rotate(45deg);
    margin: 0 0 0;
    position: relative;
    bottom: -3px;
}

.slider-area .slider-button a:hover::before {
    transform: scale(1);
}

/*<!-- ============================================================== -->
<!-- Start slider-area.style_two Css -->
<!-- ============================================================== -->*/

.slider-area.style_two{
    background: url(../../images/home2.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 850px;
}

.slider-area.style_two .slider-content .slider-title h1 {
    color: #fff;
}

.slider-area.style_two .slider-content .slider-title h2 {
    color: #fff;
}

.slider-area.style_two .slider-content p {
    font-size: 18px;
    line-height: 30px;
    color: #8f9199;
    font-weight: 400;
    width: 45%;
}

.slider-area.style_two .slider-button {
    margin-top: 42px;
    display: inline-block;
}

.slider-area.style_two .slider-button.upper {
    margin-left: 20px;
}

.slider-area.style_two .slider-button a {
    display: inline-block;
    font-size: 16px;
    background: #FF5D13;
    text-transform: uppercase;
    padding: 9px 24px;
    color: #fff;
    position: relative;
    z-index: 1;
}

.slider-area.style_two .slider-button a::before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    transform: scale(0);
    transition: .5s;
}

.slider-area.style_two .slider-button a:hover::before {
    transform: scale(1);
}

.slider-area.style_two .slider-button a:hover {
    color: #FF5D13;
}

.slider-area.style_two .slider-button.upper a {
    display: inline-block;
    font-size: 16px;
    background: inherit!important;
    text-transform: uppercase;
    padding: 8px 20px;
    color: #fff;
    position: relative;
    z-index: 1;
    border: 1px solid #fff;
}

.slider-area.style_two .slider-button.upper a::before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    transform: scale(0);
    transition: .5s;
}

.slider-area.style_two .slider_left_title h2 {
    font-size: 130px;
    line-height: 44px;
    font-weight: 700;
    margin: 0 0 0;
    text-transform: uppercase;
    transform: rotate(-90deg);
    left: -5%;
    bottom: 47%;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    color: transparent;
    opacity: 0.5;
    position: absolute;
}

.slider-area.style_two .slider-button.upper a:hover::before {
    transform: scale(1);
}

.slider-area.style_two .slider-button.upper a:hover {
    color: #FF5D13;
}



/*========================================
<-- Constre About Area style two  Css -->
==========================================*/

.about_area.style-two {
    background: url(../../images/abou_bg2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 120px 0 112px;
    position: relative;
}

.about_area.style-two p.section-description {
    font-size: 16px;
    width: 100%;
    margin: 25px 0 0;
}

.about_left.style-two {
    position: relative;
}

.about_thumb{
    position: relative;
}

.about_thumb img {
    width: 100%;
    margin-left: -40px;
}

.about-list {
    margin-bottom: 37px;
}

.about-list ul li {
    font-size: 18px;
    color: #171717;
    font-weight: 400;
    margin-bottom: 15px;
    display: inline-block;
}

.about-list ul li i {
    font-size: 17px;
    color: #171717;
    margin-right: 10px;
}

.about-btn.style-two {
    float: left;
    margin-right: 20px;
}

.about-btn.style-two a {
    display: inline-block;
    padding: 14px 36px;
}

/* about author */

.about-author {
    position: relative;
    top: -11px;
}

.about-author-thumb {
    float: left;
    margin-right: 15px;
}

.author-title h3 {
    font-size: 20px;
    color: #171717;
    font-weight: 500;
    font-family: "Yantramanav";
    margin-bottom: 3px;
}

.author-title p {
    font-size: 14px;
    color: #575f66;
    font-weight: 300;
    font-family: "Archivo";
}

.about-btn a {
    display: inline-block;
    font-size: 17px;
    color: #ffffff;
    font-weight: 500;
    font-family: "Yantramanav";
    background: #FF5E15;
    padding: 15px 33px;
    position: relative;
    z-index: 1;
}

.about-btn a::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 0%;
    background: #171717;
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.5s;
    transform: scale(0);
    opacity: 0;
}

.about-btn a:hover::after {
    transform: scale(1);
    opacity: 1;
    height: 100%;
    width: 100%;
}

.about-btn a i {
    font-size: 13px;
    font-weight: 600;
    margin-left: 12px;
    display: inline-block;
    transform: rotate(-45deg);
}

.about-shape {
    position: absolute;
    bottom: 20%;
    right: 5%;
}


/*========================================
<-- Constre Pricing Section Css -->
==========================================*/

.pricing-area {
    padding: 103px 0 89px;
    background: #F5F6F8;
}


.pricing-single-items {
    background: url(../../images/pricing_shape.png);
    margin-bottom: 30px;
    padding: 20px 20px 20px;
    position: relative;
    z-index: 1;
}

.pricing-single-items::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: rgba(23,23,23,0.8);
    top: 0;
    left: 0;
    z-index: -1;
    transform: scale(0);
    transition: .6s;
}

.pricing-single-items:hover::before {
    transform: scale(1);
}

.pricing-single-items::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: rgba(23,23,23);
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.6;
}

.pricing-single-inner {
    background: #fff;
    border: 1px solid rgba(23,23,23,0.07);
    padding: 35px 40px 49px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: .6s;
}

.pricing-single-inner::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    transform: scale(0);
    height: 100%;
    width: 100%;
    background: #171717;
    z-index: -1;
    transition: .5s;
}

.pricing-item-text {
    margin-bottom: 10px;
}

.pricing-title h3 {
    font-size: 22px;
    color: #171717;
    font-weight: 500;
    font-family: "Yantramanav";
    margin-bottom: 30px;
    transition: .5s;
}

.currency {
    font-size: 28px !important;
    font-weight: 700 !important;
    position: relative;
    top: -24px;
    line-height: 68px;
    color: #ff5e14;
}

.pricing-item-text span {
    display: inline-block;
    font-size: 70px;
    color: #ff5e14;
    font-weight: 700;
}

.month {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #575f66 !important;
    font-style: italic;
    font-family: "Archivo" !important;
    margin-left: -17px;
}

.pricing-feature {
    margin-bottom: 27px;
}

.pricing-feature ul li {
    display: block;
    padding: 4px 0;
    font-size: 16px;
    color: #575f66;
    font-weight: 300;
    font-family: "Archivo";
}

.pricing-feature ul li i {
    margin-right: 5px;
    font-size: 20px;
    position: relative;
    top: 2px;
}

.constre-btn.style1 {
    text-align: left;
}

.constre-btn.style1 a {
    display: inline-block;
    padding: 12px 37px;
}

.pricing-rcomnded {
    position: absolute;
    top: 18px;
    right: -73px;
    transform: rotate(47deg);
    opacity: 0;
    transition: .5s;
}

.pricing-rcomnded span {
    font-size: 13px;
    color: #ff5e14;
    font-weight: 400;
    font-family: "Archivo";
    text-align: center;
    background: #fff;
    padding: 12px 70px;
}

/* Constre btn */
.constre-btn {
    text-align: right;
}

.constre-btn a {
    display: inline-block;
    font-size: 17px;
    color: #ffffff;
    font-weight: 500;
    font-family: "Yantramanav";
    background: #FF5E15;
    padding: 15px 38px;
    position: relative;
    z-index: 1;
    transition: .5s;
}
.constre-btn a:hover {
    color: #FF5E15;
}

.constre-btn a::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 0%;
    background: #ffffff;
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.5s;
    transform: scale(0);
    opacity: 0;
}

.constre-btn a:hover::after {
    transform: scale(1);
    opacity: 1;
    height: 100%;
    width: 100%;
}

.constre-btn a i {
    font-size: 13px;
    font-weight: 600;
    margin-left: 12px;
    display: inline-block;
    transform: rotate(-45deg);
}

/* all hover */

.pricing-single-items:hover .pricing-single-inner::before {
    transform: scale(1);
}

.pricing-single-items:hover .pricing-single-inner {
    border: 1px solid #171717;
}

.pricing-single-items:hover .pricing-title h3 {
    color: #fff;
}


.pricing-single-items:hover .pricing-feature ul li {
    color: #fff;
}


.pricing-single-items:hover .pricing-rcomnded{
    opacity: 1;
    top: 28px;
    right: -66px;
}


/*<!-- ============================================================== -->
<!-- Start skill Area Css -->
<!-- ============================================================== -->*/

.skill-area {
    padding: 130px 0 130px;
}

.skill-area .section-title {
    text-align: left;
    margin-bottom: 25px;
}

.skill-thumb img {
    width: 100%;
    margin-left: -50px;
}

.skill-description p {
    font-size: 16px;
    line-height: 28px;
    color: #565969;
    font-weight: 400;
    margin: 0 0 0;
}


/* Slid bar */
.bg-bar {
    position: relative;
    z-index: 1;
}

.bg-bar::before {
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 95%;
    height: 1px;
    background: #E9EBEC;
    content: "";
    opacity: 0.5;
}

.bg-bar::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 17px;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background: #E11143;
    -webkit-animation-duration: 7s;
    animation-duration: 7s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: MOVE-BG;
    animation-name: MOVE-BG;
}

@-webkit-keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(615px);
            transform: translateX(615px);
  }
}

/*===================================
<-- Constre Contact  US Css -->
=====================================*/
.contact-us {
    padding: 120px 0 0px;
}

.contact-us .section-title h1 {
    margin: 0 0 0;
    font-size: 49px;
    line-height: 58px;
    color: #fff;
    font-weight: 700;
}

/*single-call-do-box*/
.single-call-do-box {
    text-align: center;
    padding: 40px 35px 25px;
    background: #F5F6F8;
    margin-bottom: 30px;
}

.single-call-do-icon i {
    text-align: center;
    line-height: 100px;
    display: inline-block;
    font-size: 30px;
    color: #FF5E14;
    margin-bottom: 18px;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: #ffffff;
    cursor: pointer;
    transition: .5s;
}

h3.call-do-title {
    font-size: 22px;
    color: #171717;
    font-weight: 500;
    font-family: "Yantramanav";
    text-align: center;
    margin-bottom: 12px;
}

.call-do-text p {
    font-size: 16px;
    color: #575f66;
    font-weight: 300;
    font-family: "Archivo";
    text-align: center;
    margin: 0;
    padding-bottom: 6px;
}

.single-call-do-box:hover .single-call-do-icon i {
    background: #FF5E14;
    color: #fff;
}

.row.contact-center {
    margin: 0;
    padding: 0 175px 0;
    margin-top: 69px;
    z-index: 1;
    position: relative;
}

.contact_from_box {
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
    background: #171717;
    padding: 67px 50px 80px;
    border-top: 5px solid #FF5F15;
}

.form_box input {
    height: 60px;
    background-color: #ffffff;
    width: 100%;
    border: 0;
    outline: 0;
    padding: 0 20px;
    margin-bottom: 20px;
}

.form_box input::placeholder {
    font-size: 15px;
    color: #575f66;
    font-weight: 300;
    font-family: "Archivo";
}

.form_box textarea {
    width: 100%;
    height: 200px;
    padding: 15px 20px;
}

.form_box textarea::placeholder {
    font-size: 15px;
    color: #575f66;
    font-weight: 300;
    font-family: "Archivo";
}

.quote_button {
    text-align: center;
    margin-top: 20px;
}

.contact-us button.btn {
    font-size: 17px;
    color: #ffffff;
    font-weight: 500;
    font-family: "Yantramanav";
    background: #FF5E15;
    border-radius: 0;
    padding: 16px 38px;
    position: relative;
    z-index: 1;
    transition: .5s;
}

button.btn:hover {
    color: #171717;
}

button.btn::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 0%;
    background: #fff;
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.5s;
    transform: scale(0);
    opacity: 0;
}

button.btn:hover::after {
    transform: scale(1);
    opacity: 1;
    height: 100%;
    width: 100%;
}

/* map section */

.map-section {
    padding: 120px 0 20px;
}

.map-section .row.mg-pt {
    overflow: hidden;
    border-radius: 20px;
}


/*<!-- ============================================================== -->
<!-- Start Carousel Css -->
<!-- ============================================================== -->*/

.counter-area {
    background: #F2F3F5;
    padding: 130px 0 130px;
}

.counter-area .counter-text h1 {
    margin: 0 0 6px;
    font-size: 80px;
    line-height: 58px;
    color: #ff5e14;
    font-weight: 700;
    text-align: center;
}

.counter-area .counter-title h4 {
    margin: 0 0 0;
    font-size: 18px;
    line-height: 35px;
    color: #565969;
    font-weight: 400;
    text-align: center;
}

.counter-right {
    text-align: right;
    position: relative;
    top: -45%;
}

.counter-right .counter-button a {
    display: inline-block;
    font-size: 16px;
    background: #FF5E15;
    text-transform: uppercase;
    padding: 12px 22px;
    color: #fff;
    position: relative;
    z-index: 1;
}

.counter-right .counter-button a::before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #0A1028;
    transform: scale(0);
    transition: .5s;
}

.counter-right .counter-button a i {
    display: inline-block;
    font-size: 25px;
    transform: rotate(45deg);
    margin: 0 0 0;
    position: relative;
    bottom: -3px;
}

.counter-right .counter-button a:hover::before {
    transform: scale(1);
}

.counter-single-item-inner {
    background: #fff;
    height: 350px;
    width: 350px;
    line-height: 350px;
    text-align: center;
    margin: 0 auto 0;
    border-radius: 50%;
}

.counter-single-item-inner.upper {
    border: 1px solid #E2E3E5;
    background: inherit;
}

.counter-content {
    position: relative;
    top: 35%;
    z-index: 1;
}

/*<!-- ============================================================== -->
<!-- Start testimonial-area Css -->
<!-- ============================================================== -->*/

.counter-area.style_two {
    background: url(../../images/counter-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 130px 0 130px;
}

.counter-area.style_two .counter-single-item-inner {
    background: inherit;
    position: relative;
    z-index: 1;
}

.counter-area.style_two .counter-single-item-inner::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    border-radius: 50%;
    opacity: 0.1;
    z-index: -1;
}

.counter-area.style_two .counter-single-item-inner.upper {
    border: 1px solid #A8A8A8;
    background: inherit;
}

.counter-area.style_two .counter-single-item-inner.upper::before {
    background: inherit;
}

.counter-area.style_two .counter-text h1 {
    color: #fff;
}

.counter-area.style_two .counter-title h4 {
    color: #909098;
}


/**
======================================================
<--  Constre Service Details Area Css -->
======================================================**/
.service-detials-area {
    padding: 120px 0 100px;
}

.Constre-service-thumb img {
    width: 100%;
}

.service-page-title h1 {
    font-size: 34px;
    color: #171717;
    font-weight: 700;
    font-family: "Yantramanav";
    padding: 22px 0 12px;
    display: inline-block;
}

p.pb_30 {
    margin-bottom: 31px;
    margin-top: 24px;
}

/*detials box*/
.service-details-box {
    background: #fff;
    padding: 33px 30px 15px;
    margin-bottom: 30px;
    border-left: 3px solid #FF5D13;
    filter: drop-shadow(0px 5px 30px rgba(117,117,117,0.1));
}

.service-details-box:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 0%;
    background: #FF5D13;
    z-index: -1;
    transition: .5s;
}

.service-details-box:hover:before {
    width: 100%;
}

.service-details-icon {
    float: left;
    margin-right: 25px;
    margin-top: 10px;
    background: #F5F6F8;
    line-height: 70px;
    width: 70px;
    height: 70px;
    text-align: center;
    transition: .5s;
}
.service-details-icon img {
    transition: .5s;
}

.service-details-title h4 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 12px;
    transition: .5s;
}

.services-detials-desc p {
    transition: .5s;
}

/* all hover */

.service-details-box:hover .service-details-icon {
    background: #2F2F2F;
}

.service-details-box:hover  .service-details-icon img {
    filter: brightness(0)invert(1);
}

.service-details-box:hover  .service-details-title h4 {
   color: #fff;
}

.service-details-box:hover  .services-detials-desc p {
    color: #fff;
 }

 /* service tab title */

 .service-details-content {
    margin-bottom: 30px;
}

 .service-tab-title h2 {
    font-size: 34px;
    color: #171717;
    font-weight: 700;
    margin: 69px 0 32px;
}

.service-page-title2 h1 {
    font-size: 34px;
    color: #171717;
    font-weight: 700;
    font-family: "Yantramanav";
    margin: 50px 0 18px;
}

/* service-details-item */

.service-details-item {
    position: relative;
    margin-bottom: 30px;
    padding: 0 0 131px;
}

.service-details-thumb {
    position: relative;
    overflow: hidden;
}

.service-details-thumb img {
    transform: scale(1.1);
    transition: .5s;
}

.service-details-item:hover .service-details-thumb img {
    transform: scale(1.2);
}

.service-details-contents {
    background: #fff;
    padding: 21px 30px 25px;
    width: 85%;
    position: absolute;
    left: 1px;
    right: 0;
    margin: auto;
    margin-top: -20px;
    filter: drop-shadow(0px 5px 30px rgba(117,117,117,0.1));
}

.service-details-contents h3 a {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 14px;
    display: inline-block;
    color: #171717;
}

ul.service-details-list li {
    margin-bottom: 3px;
    display: inline-block;
}

ul.service-details-list li i {
    font-size: 20px;
    color: #FF5E14;
    position: relative;
    top: 2px;
    margin-right: 8px;
}

/*widget categories box*/
.widget-categories-box {
    background: #F5F6F8;
    padding: 26px 40px 25px;
    margin-bottom: 30px;
}

.categories-title h4 {
    font-size: 22px;
    font-weight: 600;
    position: relative;
    margin-bottom: 52px;
    z-index: 1;
}

.categories-title h4::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: -23px;
    height: 2px;
    background: #DEDFE0;
    width: 100%;
}

.categories-title h4:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: -23px;
    height: 2px;
    background: #FF5E14;
    width: 15px;
}


/*categories menu*/
.widget-categories-menu ul {
    display: block;
}

.widget-categories-menu ul li {
    display: block;
    list-style: none;
    margin-bottom: 15px;
    background: #fff;
    padding: 16px 20px 16px;
    transition: .5s;
    position: relative;
    z-index: 1;
    font-size: 16px;
    color: #171717;
}

.widget-categories-menu ul li:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 0%;
    background: #FF5E15;
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.5s;
    transform: scale(0);
    opacity: 0;
}

.widget-categories-menu ul li:hover:after {
    transform: scale(1);
    opacity: 1;
    height: 100%;
    width: 100%;
}

.widget-categories-menu ul li a {
    display: block;
    transition: .5s;
    color: inherit;
}


.widget-categories-menu ul li a span {
    display: inline-block;
    float: right;
    font-size: 16px;
    margin-top: 0px;
    color: #FF5E15;
    transition: .5s;
    font-weight: 600;
}

/*all hover*/

.widget-categories-menu ul li:hover a span {
    color: #ffff;
}

.widget-categories-menu ul li:hover a {
    color: #fff;
}

.service-details-icon-box {
    margin-left: 20px;
}

/* widget box */

.widget-categories-box.style-down {
    background: #171717;
}

.widget-categories-box.style-down .categories-title h4 {
    color: #fff;
}

.categories-title.style1 h4::before {
    background: #2F2F2F;
}

/* widget pdf */

.pdf-button a {
    display: block;
    background: #2F2F2F;
    padding: 15px 25px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 15px;
}

.pdf-button a i {
    float: right;
    font-size: 20px;
    margin-top: 2px;
    color: #FF5E15;
}

.pdf-button a img {
    margin-right: 10px;
}

/*<!-- ============================================================== -->
<!-- Start testimonial-area Css -->
<!-- ============================================================== -->*/

.testimonial-area {
    background: url(../../images/testi-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 130px 0 130px;
}

.testimonial-area .section-title h1 {
    color: #fff;
}

.testimonial-area .section-title {
    text-align: left;
    margin-bottom: 40px;
}

.single-testimonial {
    overflow: hidden;
    position: relative;
    left: -30px;
}

.singles-testimoni-box {
    background: #fff;
    overflow: hidden;
    position: relative;
    z-index: 1;
    left: 30px;
}

.testimonial-icon {
    margin: 0 0 -10px;
}

.testimonial-icon i {
    display: inline-block;
    font-size: 60px;
    color: #FF5D13;
    transform: rotate(178deg);
}

.testimonial-rating ul li {
    display: inline-block;
    margin: 0 3px 0;
    font-size: 18px;
    color: #FF5E15;
    list-style: none;
}

.testimonial-rating {
    text-align: right;
    position: relative;
    top: -22px;
}

.testimonial-description p {
    opacity: 0.800;
    font-size: 16px;
    line-height: 28px;
    color: #ffffff;
    font-weight: 400;
    font-family: "Archivo";
    margin: 0 0 25px;
    border-bottom: 1px solid #262D40;
    padding: 0 0 25px;
}

.testimonial-title h3 {
    font-size: 23px;
    line-height: 35px;
    color: #fefefe;
    font-weight: 500;
    margin: 0 0 0;
}

.testimonial-title span {
    font-size: 16px;
    line-height: 28px;
    color: #ff5e14;
    font-weight: 400;
    font-family: "Archivo";
}

/* table
================*/

table.category-table {
    padding: 2px 0 0;
    display: inline-block;
}

table.category-table td.table-title {
    font-size: 16px;
    color: #062461;
    font-weight: 500;
}

table.category-table tr td {
    padding-right: 25px;
    padding-bottom: 12px;
}

table.category-table td.table-title {
    font-size: 16px;
    color: #0D0E14;
    font-weight: 500;
}

table.category-table td.table-text {
    color: #616161;
    position: relative;
    z-index: 1;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Inter';
}

table.category-table td.table-text::before {
    position: absolute;
    content: ":";
    left: -15px;
    top: 0;
    font-size: 20px;
    color: #0D0E14;
}

/*Tab Items
=======================*/

.tab {
    padding-top: 10px;
    position: relative;
    left: 10px;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
}

.tab_content {
    position: absolute;
    top: 0;
    right: 0;
}

.tabs {
    display: table;
    position: relative;
    overflow: hidden;
    margin: 0;
    width: 100%;
}

.tabs li {
    line-height: 38px;
    margin-bottom: 10px;
    position: relative;
    display: block;
}

.appoinment-tab .tabs li a {
    font-size: 15px;
    background: #fff;
    border: 1px solid rgba(13,14,20,0.1);
    color: #333;
    font-family: 'Inter';
    font-weight: 500;
    display: inline-block;
    outline: none;
    padding: 9px 29px;
    transition: all 0.5s ease-in-out;
    margin-right: 20px;
    border-radius: 5px;
    text-decoration: none;
}

.tabs li a span {
    font-size: 22px;
    padding-right: 10px;
    color: #da242b;
}

.tabs_item {
    display: none;
    padding: 30px 0;

}

.tabs_item:first-child {
    display: block;
}

.tab-thumb {
    margin-right: 65px;
    position: relative;
    z-index: 1;
}

.tab-thumb::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    clip-path: polygon(0 0, 100% 0%, 0% 100%);
    transform: scale(0);
    transform-origin: top left;
    background-image: linear-gradient(-86deg, #062462 0%, #062462 100%);
    pointer-events: none;
    transition: all 500ms linear;
    border-radius: 5px;
    opacity: .25;
}

.tab-thumb img {
    width: 100%;
    border-radius: 5px;
}

.appoinment-tab li.current a {
    background: #FD8F14;
    color: #fff;
}

li.current a span {
    color: #fff;
}

.tabs_item .hendre-section-title p {
    padding: 17px 0 5px;
}

.tabs_item .hendre-section-title h4 {
    margin-top: 0;
}

.tabs_item .hendre-button a {
    margin-top: 30px;
}

.tab-thumb:hover::before {
    transform: scale(1);
}

/*===================================
<-- Constre Case Study Style Two Css -->
=====================================*/
.case-study-area.style-two {
    background: #fff;
    padding: 105px 0 120px;
}

/* Portfolio Nav */
.portfolio_nav {
    margin-top: 98px;
}

.portfolio_menu ul {
    text-align: right;
    list-style: none;
}

.portfolio_nav ul li {
    transition: all 0.5s ease 0s;
    cursor: pointer;
    padding: 7px 22px;
    display: inline-block;
    border: 1px solid rgba(23,23,23,0.1);
    margin-left: 10px;
    font-size: 17px;
    color: #171717;
    font-weight: 400;
    font-family: "Yantramanav";
    position: relative;
    z-index: 1;
}

.portfolio_nav ul li:hover {
    color: #fff;
    border: 1px solid #171717;
}

.portfolio_nav ul li:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 0%;
    background: #171717;
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.5s;
    transform: scale(0);
    opacity: 0;
}

.portfolio_nav ul li:hover:after {
    transform: scale(1);
    opacity: 1;
    height: 100%;
    width: 100%;
}


.portfolio_menu ul li a {
    display: block;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    transition: .5s;
}

.portfolio_menu ul li a::before {
    position: absolute;
    content: "";
    left: 0;
    top: 24px;
    height: 2px;
    width: 0%;
    background: #fff;
    transition: .5s;
}

.portfolio_nav ul li:hover,
.portfolio_nav ul li.current_menu_item {
    color: #fff;
    background: #FF5E14;
    
}

.style-two .case-study-single-box {
    margin-bottom: 30px;
}

.case-study-thumb2 {
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.case-study-thumb2 img {
    transform: scale(1.1);
    transition: .5s;
}

.case-study-thumb2 img:hover {
    transform: scale(1.2);
}

.case-study-contents {
    position: absolute;
    bottom: -84px;
    background-image: linear-gradient(90deg, rgba(255,255,255,0.9999999999999999) 0%, rgba(255,255,255,0.9999999999999999) 9%, rgba(255,255,255,0) 100%);
    border: 2px solid rgba(255,255,255,0.7);
    width: 90%;
    padding: 19px 0 24px;
    text-align: left;
    border-left: 3px solid #FF5E15;
    padding-left: 30px;
    left: 20px;
    transition: .5s;
    opacity: 0;
}

.case-study-title.style2 h3 a {
    display: inline-block;
    font-size: 24px;
    color: #171717;
    font-weight: 700;
    font-family: "Yantramanav";
}

.case-title.style2 span a {
    display: inline-block;
    font-size: 18px;
    color: #ff5e14;
    font-weight: 400;
    font-family: "Yantramanav";
}

.constre-btn.style2 {
    text-align: center;
    margin-top: 50px;
}

.constre-btn.style2 a {
    display: inline-block;
    background: #171717;
    padding: 15px 45px;
}

.constre-btn.style2 a:hover {
    color: #fff;
}

.constre-btn.style2 a::after {
    background: #FF5E14;
}

/* all hover */
.case-study-single-box:hover .case-study-contents {
    bottom: 20px;
    opacity: 1;
}

/*======================================
<-- Constre Case Study Details Css -->
========================================*/
.case-study-details {
    padding: 120px 0 120px;
}

.csde-title h2 {
    font-size: 34px;
    color: #171717;
    font-weight: 700;
    font-family: "Yantramanav";
    margin: 41px 0 15px;
}

p.desc-strong {
    font-size: 17px;
    line-height: 26px;
    color: #171717;
    font-weight: 400;
    font-style: italic;
    font-family: "Archivo";
    margin: 25px 0 47px;
}

.porject-title {
    margin-bottom: 37px;
}

.porject-title.style-2 {
    margin: 55px 0 18px;
}

.porject-title h2 {
    font-size: 34px;
    color: #171717;
    font-weight: 700;
    font-family: "Yantramanav";
}

p.style1 {
    margin-bottom: 35px;
}

.porject-list ul li {
    font-size: 17px;
    color: #575f66;
    font-weight: 400;
    font-family: "Archivo";
    margin-bottom: 15px;
    display: inline-block;
}

.porject-list ul li i {
    color: #FF5E14;
    font-size: 20px;
    margin-right: 12px;
}

.csd-thumb img {
    width: 100%;
}

/* case sidber */

.case-sidber {
    filter: drop-shadow(0px 5px 30px rgba(117,117,117,0.1));
    background-color: #ffffff;
    position: relative;
    margin-top: -81px;
    margin-bottom: 30px;
}

.csd-title {
    margin-bottom: 30px;
}

.csd-title h3 {
    font-size: 22px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Yantramanav";
    background: #FF5E14;
    padding: 23px 40px 23px;
}

.csd-info-sidber-box {
    padding: 0 40px 1px;
}

.sidber-box {
    margin-bottom: 20px;
    border-bottom: 1px solid #F4F5F7;
    padding-bottom: 20px;
}

.sidber-box:last-child {
    border: none;
}

.case-info-icon {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: #f5f6f8;
    line-height: 60px;
    text-align: center;
    display: inline-block;
    float: left;
    margin-right: 20px;
}

.case-info-icon span i {
    color: #FF5E14;
}

.case-info-address {
    display: inline-block;
}

h3.name-title {
    font-size: 18px;
    color: #171717;
    font-weight: 500;
    font-family: "Yantramanav";
    margin-top: 8px;
}

/* porject sideber box */

.porject-sidebar-box {
    background: #171717;
    padding: 26px 40px 40px;
}

.porject-share-title h3 {
    font-size: 22px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Yantramanav";
    position: relative;
    z-index: 1;
    margin-bottom: 36px;
}

.porject-share-title h3:before {
    position: absolute;
    content: "";
    left: 0;
    bottom: -23px;
    height: 2px;
    background: #dedfe01a;
    width: 100%;
}

.porject-share-title h3:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: -23px;
    height: 2px;
    background: #FF5E14;
    width: 15px;
}

p.share-text {
    color: #8e939f;
    margin-bottom: 14px;
}

.csd-social-icon ul li {
    display: inline-block;
    margin-right: 8px;
}

.csd-social-icon ul li a {
    font-size: 16px;
    color: #ffffff7a;
    font-family: "Font Awesome";
    text-align: center;
    width: 40px;
    height: 40px;
    background-color: rgba(255,255,255,0.10196078431372549);
    display: inline-block;
    line-height: 40px;
}

.csd-social-icon ul li a:hover {
   background: #FF5E14;
   color: #fff;
}

.row.upper-pb {
    margin-bottom: 90px;
}

/* pagination */

.pagination-bg {
    background: #F5F6F8;
    margin: 0;
    padding: 33px 20px 27px 40px;
}

.pagination-single-item {
    display: inline-block;
}

.pagination-single-item.right {
    display: inline-block;
    float: right;
    position: relative;
    z-index: 1;
}

.pagination-single-item.right:before {
    position: absolute;
    content: "";
    left: -85%;
    top: 4px;
    opacity: 0.102;
    width: 2px;
    height: 50px;
    background-color: rgb(23 23 23 / 49%);
    z-index: -1;
}

.pagination-btn {
    margin-right: 20px;
    float: left;
    position: relative;
    top: 6px;
}

.pagination-btn.right {
    float: right;
    display: inline-block;
    margin-left: 20px;
}

.pagination-btn a {
    display: inline-block;
    width: 50px;
    height: 50px;
    background-color: #ffffff;
    line-height: 50px;
    text-align: center;
    color: #FF5E14;
    font-size: 16px;
}

.pagination-content {
    overflow: hidden;
}

.pagination-content.right {
    text-align: right;
    display: inline-block;
}

h3.pagination-title {
    font-size: 22px;
    color: #171717;
    font-weight: 700;
    font-family: "Yantramanav";
    margin-top: 8px;
}

.pagination-content span {
    font-size: 17px;
    color: #ff5e14;
    font-weight: 400;
    font-family: "Archivo";
}


/*<!-- ============================================================== -->
<!-- Start service-area Css -->
<!-- ============================================================== -->*/

.service-area {
    padding: 120px 0 120px;
}

.service-area .section-title {
    text-align: center;
    margin-bottom: 50px;
}

.service-area .single-service-box {
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding: 0 0px 30px;
    margin: 0 15px 30px;
}

.service-area .single_service_box::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 0%;
    width: 100%;
    transition: .5s;
    background: #E01142;
    z-index: -1;
    border-radius: 5px;
}

.service-area .service-thumb {
    position: relative;
    z-index: 1;
}

.service-area .service-thumb::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 0%;
    width: 100%;
    background: #0A1028;
    opacity: 0.4;
    transition: .5s;
}

.service-area .service-thumb img {
    width: 100%;
}

.service-area .service-content {
    position: relative;
    z-index: 1;
    top: -30px;
    left: 30px;
    box-shadow: 0 0 16px rgb(0 0 0 / 10%);
    padding: 25px 30px 40px;
    background: #fff;
}

.service-area .service-content h3 {
    font-size: 27px;
    line-height: 28px;
    color: #0a1027;
    font-weight: 700;
    margin: 15px 0 12px;
}

.service-area .service-content p {
    font-size: 16px;
    line-height: 28px;
    color: #565969;
    font-weight: 400;
    margin: 0 0 0;
}

.service-area .service-button {
    margin-top: 30px;
}

.service-area .service-button a {
    display: inline-block;
    font-size: 15px;
    line-height: 28px;
    color: #FF5E15;
    font-weight: 500;
    background: #F2F3F5;
    text-transform: uppercase;
    padding: 6px 22px;
    transition: .5s;
    position: relative;
    z-index: 1;
}

.service-area .service-button a::before {
    position: absolute;
    z-index: -1;
    content: "";
    top: 0;
    right: 0;
    height: 100%;
    width: 0%;
    transition: .5s;
    background: #FF5D13;
}

.service-area .service-icon {
    position: absolute;
    top: -40px;
    right: 60px;
    background: #fff;
    padding: 25px 24px;
    border-radius: 50%;
    transition: .5s;
    box-shadow: 0 0 60px rgb(0 0 0 / 10%);
    transition: .5s;
    border-top: 3px solid #FF5D13;
}

.service-area .single-service-box .service-icon img {
    transition: .5s;
    position: relative;
    z-index: 1;
}

.service-area .single-service-box .service-icon img::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #FF5D13;
    z-index: -1;
}

.service-area .single-service-box:hover .service-icon {
    background: #FF5D13;
    padding: 24px 24px;
}

.service-area .single-service-box:hover .service-button a {
    color: #fff;
}

.service-area .single-service-box:hover .service-button a::before {
    width: 100%;
    left: 0;
}

.service-area .single-service-box:hover .service-thumb::before {
    height: 100%;
}

.service-area .single-service-box:hover .service-icon img{
    filter: brightness(0)invert(1);
}

.owl-dots {
    position: relative;
    text-align: center;
}

.owl-dot {
    display: inline-block;
    height: 12px;
    width: 12px;
    border: 2px solid #FF5E15;
    margin: 0 8px 0;
    border-radius: 50%;
    background: #fff;
    position: relative;
    text-align: center;
}

.owl-dot.active {
    border: 1px solid #FF5E15;
}

.owl-dot.active::before {
    content: '';
    position: absolute;
    left: -5px;
    top: -5px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #FF5E15;
}

/*<!-- ============================================================== -->
<!-- Start service-area.style_two Css -->
<!-- ============================================================== -->*/

.service-area.style_two .single-service-box {
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding: 0 0px 0px;
    margin: 0 15px 30px;
}

/*<!-- ============================================================== -->
<!-- Start blog-area Css -->
<!-- ============================================================== -->*/

.blog-area {
    padding: 130px 0 100px;
    background: #F2F3F5;
}

.blog-area .section-title {
    text-align: left;
    margin-bottom: 0;
}

.blog-area .blog-top-btn {
    text-align: right;
    position: relative;
    top: -50%;
}

.blog-area .blog-top-btn a {
    display: inline-block;
    font-size: 16px;
    background: #FF5E15;
    text-transform: uppercase;
    padding: 12px 22px;
    color: #fff;
    position: relative;
    z-index: 1;
}

.blog-area .blog-top-btn a::before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #0A1028;
    transform: scale(0);
    transition: .5s;
}

.blog-area .blog-top-btn a i {
    display: inline-block;
    font-size: 25px;
    transform: rotate(45deg);
    margin: 0 0 0;
    position: relative;
    bottom: -3px;
}

.blog-area .blog-top-btn a:hover::before {
    transform: scale(1);
}

.blog-area .blog-singles-boxs {
    background: #fff;
    padding: 12px 12px 1px;
    margin-bottom: 30px;
    transition: .5s;
}

.blog-area .blog-single-box {
    padding: 11px 10px 50px;
    transition: .5s;
    margin-bottom: 30px;
    background: #fff;
}

.blog-area .blog-single-box .blog-content {
    margin-top: 19px;
    padding: 0 0 0 30px;
}

.blog-area .blog-single-box .blog-thumb img {
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
}

.blog-singles-boxs .blog-content {
    display: inline-block;
    position: absolute;
    margin-top: 17px;
}

.blog-content h3 a {
    margin: 0px 0 17px;
    display: inline-block;
    transition: .5s;
    border-bottom: 1px solid #C1C1C1;
    padding: 0 0 25px;
    font-size: 27px;
    line-height: 35px;
    color: #0a1027;
    font-weight: 700;
}

.blog-content h4 span {
    position: relative;
    z-index: 1;
    margin-right: 18px;
    font-size: 16px;
    line-height: 35px;
    color: #565969;
    font-weight: 400;
}

.blog-content h4 span i {
    display: inline-block;
    font-size: 20px;
    color: #FF5D13;
    margin-right: 5px;
}

.blog-content h4 i {
    display: inline-block;
    font-size: 20px;
    color: #FF5D13;
    margin-right: 5px;
}

.blog-thumb {
    position: relative;
    z-index: 1;
    transition: .5s;
    display: inline-block;
}

.blog-thumb img::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #012549;
    border-radius: 5px;
    opacity: 0.5;
    transition: .5s;
}

.blog-thumb img {
    position: relative;
    z-index: 1;
    transition: .5s;
    margin: 0 30px 10px 0px;
}

.blog-details-thumb img {
    width: 100%;
}

.blog-content h4 {
    font-size: 15px;
    line-height: 26px;
    color: #6f7785;
    font-weight: 400;
    margin: 0;
    transition: .5s;
}

.blog-button {
    margin: 15px 0 0;
}

.blog-button a {
    display: inline-block;
    font-size: 16px;
    transition: .5s;
    position: relative;
    z-index: 1;
    color: #FF5E15;
    padding: 6px 27px;
    background: #F2F3F5;
}

.blog-button a::before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #FF5D13;
    transform: scale(0);
    transition: .5s;
}

.blog-button a i{
    display: inline-block;
    font-size: 16px;
    transition: .5s;
}

.blog-content h3 a:hover{
     color: #FF5D13;
}

.blog-singles-boxs:hover .blog-button a{
     color: #fff;
}

.blog-singles-boxs:hover .blog-button a::before{
     transform: scale(1);
}

.blog-single-box:hover .blog-button a{
     color: #fff;
}

.blog-single-box:hover .blog-button a::before{
     transform: scale(1);
}

.blog-singles-boxs:hover .blog-content h3 a{
     border-bottom: 1px solid #FF5D13;
}

.blog-singles-boxs:hover .blog-thumb img::before{
     height: 100%;
}

/* blog sidebar */

.widget-items-search {
    position: relative;
    margin-bottom: 30px;
}

.src-input-box:focus {
    border: 1px solid #ccc;
}

input.src-input-box::placeholder {
    font-size: 15px;
    color: #575f66;
    font-weight: 300;
    font-family: "Archivo";
}

.src-icon {
    position: absolute;
    right: 0;
    top: 32px;
    transform: translateY(-50%);
    font-size: 15px;
    border: none;
    background: #FF5E15;
    padding: 17px 25px;
    color: #fff;
}

/*search items*/
.widget-items {
    background: #F5F6F8;
    border-radius: 3px;
    padding: 40px 40px 15px;
    margin-bottom: 30px;
}

.src-input-box {
    border: 1px solid #cccccc57;
    padding: 17px 30px;
    width: 100%;
}


/*categories*/
.widget-title {
    margin-bottom: 28px;
    position: relative;
}

.widget-title h2 {
    margin-top: 0;
}

.widget-archieve-item {
    color: #232323;
}

.widget-archieve-item:hover {
    color: #ff3b00;
}

.widget-items .widget-title h2 {
    display: block;
    position: relative;
    z-index: 1;
    font-size: 22px;
    color: #171717;
    font-weight: 700;
    font-family: "Yantramanav";
}

.widget-title h2::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -20px;
    width: 100%;
    height: 2px;
    background-color: rgba(23,23,23,0.10196078431372549);
}

.widget-title h2::after {
    content: "";
    position: absolute;
    left: 0;
    top: 44px !important;
    width: 20px;
    height: 2px;
    background-color: #ff5e14;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-name: MOVE-BG;
}

@keyframes MOVE-BG {
    0% {
        left: 0px;
        top: 0px;
    }

    100% {
        left: 260px;
        top: 0px;
    }
}

.hr-3 {
    border-top: 1px solid #e6e6e6;
    padding-top: 15px;
}

.catagory-item {
    margin: 43px 0 7px;
}

.catagory-item ul li {
    border-bottom: 1px solid rgba(23,23,23,0.10196078431372549);
    display: block;
}

.catagory-item ul li:last-child {
    border-bottom: none;
}

.catagory-item ul li a {
    font-size: 18px;
    color: #171717;
    font-weight: 400;
    font-family: "Yantramanav";
    display: inline-block;
    padding: 10px 0;
}

.catagory-item ul li:hover a {
    color: #FF5E15;
}


.catagory-item ul li a i {
    color: #FF5E15;
    font-size: 15px;
    margin-right: 14px;
}

/*post thumb*/

.widget-recent-post {
    margin-bottom: 23px;
    padding-top: 24px;
}

.rpost-thumb {
    margin-right: 20px;
}

/*post title*/
.rpost-title h4 {
    transition: .5s;
    margin: 0;
    font-size: 18px;
    line-height: 24px;
    color: #171717;
    font-weight: 500;
    font-family: "Yantramanav";
}

.rpost-title span {
    font-size: 14px;
    display: inline-block;
}

.rpost-title h4:hover {
    color: #FF5E15;
}

/*tag menu*/

.tag-item {
    margin: 50px 0 17px;
}

.tag-item a {
    padding: 8px 20px;
    display: inline-block;
    margin: 2px 3px 8px;
    transition: .5s;
    font-size: 16px;
    line-height: 26px;
    color: #171717;
    font-weight: 500;
    font-family: "Yantramanav";
    background: #fff;
}

.tag-item a:hover {
    color: #fff;
    background: #FF5E15;
}

/*pagination*/
.pagination.center {
    text-align: center !important;
    display: flex;
    align-items: center !important;
    justify-content: center;
    margin-top: 50px;
}

.pagination a {
    font-size: 18px;
    font-weight: 500;
    color: #919191;
    float: left;
    text-decoration: none;
    transition: background-color .3s;
    margin: 0 4px;
    height: 42px;
    width: 42px;
    line-height: 42px;
    text-align: center;
    border: 1px solid rgba(33,33,33,0.1);
}

.pagination a:hover {
    background: #FF5E15;
    color: #fff;

}

/*<!-- ============================================================== -->
<!-- Constre Blog Details Css -->
<!-- ============================================================== -->*/

.blog-details {
    background: #fff;
    padding: 120px 0 100px;
}

.blog-content {
    transition: .5s;
}

.blog-meta {
    border-bottom: 1px solid rgba(23,23,23,0.10196078431372549);
    padding: 22px 0 25px;
}

.blog-meta span {
    padding-left: 20px;
    font-size: 16px;
    color: #575f66;
    font-weight: 400;
    font-family: "Yantramanav";
    position: relative;
    z-index: 1;
    padding-right: 25px;
}

.blog-meta span:before {
    position: absolute;
    z-index: -1;
    width: 10px;
    height: 10px;
    background-color: #d1d1d1;
    content: "";
    top: 5px;
    left: 0;
}

.blog-meta span a {
    color: #575f66;
    transition: .5s;
    display: inline-block;
}

.blog-meta span a:hover {
    color: #FF5E15;
}

.blog-content-text.text-left h2 {
    font-size: 34px;
    color: #171717;
    font-weight: 700;
    font-family: "Yantramanav";
    margin: 25px 0 14px;
}

p.qouik2 {
    margin: 24px 0 35px;
}

/*blog tetails items*/

.blog-details-single-items {
    background: #F5F6F8;
    text-align: center;
    padding: 40px 19px 30px;
}

.blog-details-desc p {
    font-size: 22px;
    line-height: 36px;
    color: #171717;
    font-weight: 400;
    font-style: italic;
    font-family: "Archivo";
    text-align: center;
    padding: 20px 0 10px;
}

h3.blog-details-title {
    font-size: 22px;
    color: #ff5e14;
    font-weight: 700;
    font-family: "Yantramanav";
    text-align: center;
    margin-bottom: 6px;
}

h2.blog-dtls-title3 {
    font-size: 34px;
    color: #171717;
    font-weight: 700;
    font-family: "Yantramanav";
    margin: 56px 0 18px;
}

p.desc-style1 {
    margin-bottom: 36px;
}

/* blog details list thumb */

.blog-details-list-item {
    margin-top: 18px;
}

h3.blog-details-list-title {
    font-size: 24px;
    color: #171717;
    font-weight: 700;
    font-family: "Yantramanav";
    margin-bottom: 17px;
}

ul.blog-details-list li {
    font-size: 16px;
    color: #171717;
    font-weight: 500;
    font-family: "Archivo";
    margin-bottom: 8px;
    display: block;
}

ul.blog-details-list img {
    margin-right: 10px;
}

/*blog social*/

.blog-details-social {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(23,23,23,0.1);
    border-bottom: 1px solid rgba(23,23,23,0.1);
    padding: 16px 0 16px;
    margin-top: 60px;
    margin-bottom: 65px;
}

.blog-social-tags span strong {
    font-size: 18px;
    color: #171717;
    font-weight: 500;
    font-family: "Yantramanav";
    margin-right: 20px;
}

.single-blog-social-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.share-text {
    margin-right: 20px;
}

.share-text h4 {
    margin: 0;
    font-size: 18px;
    color: #171717;
    font-weight: 500;
    font-family: "Yantramanav";
}


.single-blog-social-icon ul li {
    display: inline-block;
    margin-right: 5px;
}

.single-blog-social-icon ul li i {
    font-size: 15px;
    height: 34px;
    width: 34px;
    line-height: 34px;
    text-align: center;
    border: 1px solid #ccc;
    display: inline-block;
    color: #686868;
    transition: .5s;
}

.single-blog-social-icon ul li:hover i {
    color: #fff;
    background: #ff5e14;
}


/* Comment CSS */

.blog-details-contents h3 {
    font-size: 28px;
    color: #171717;
    font-weight: 700;
    font-family: "Yantramanav";
    margin-bottom: 32px;
}

.blog-details-comment {
    margin-bottom: 20px;
    position: relative;
    background: #F5F6F8;
    padding: 33px 40px 24px 30px;
}

.blog-details-comment-thumb {
    float: left;
    margin-right: 25px;
}

.blog-details-comment-content {
    overflow: hidden;
}

.blog-details-comment-content h2 {
    margin-top: 0;
    font-size: 20px;
    color: #171717;
    font-weight: 700;
    font-family: "Yantramanav";
    margin-bottom: 8px;
}

.blog-details-comment-reply span {
    display: inline-block;
    font-size: 16px;
    color: #575f66;
    font-weight: 300;
    font-family: "Archivo";
    position: relative;
    z-index: 1;
    margin-right: 25px;
}

.blog-details-comment-reply span:before {
    position: absolute;
    content: "";
    right: -16px;
    top: 6px;
    width: 2px;
    height: 15px;
    background-color: rgba(23,23,23,0.14901960784313725);
    z-index: -1;
}

.blog-details-comment-reply a {
    font-size: 14px;
    border-radius: 2px;
    display: inline-block;
    font-size: 16px;
    color: #ff5e14;
    font-weight: 500;
    font-family: "Yantramanav";
}

.blog-details-comment-reply a i {
    margin-right: 10px;
}

.blog-details-comment.reply {
    margin-left: 80px;
}

/* commet form */
.comment-form {
    margin-top: 56px;
}
.comment-title h3 {
    font-size: 28px;
    color: #171717;
    font-weight: 700;
    font-family: "Yantramanav";
    margin-bottom: 27px;
}

#comment-msg-box {
    width: 100%;
    transition: .5s;
    height: 200px;
    background-color: #ffffff;
    border: 1px solid rgba(23,23,23,0.1);
    padding: 21px 25px;
}

#comment-msg-box::placeholder {
    font-size: 15px;
    color: #575f66;
    font-weight: 300;
    font-family: "Archivo";
}

input.comment-box {
    height: 60px;
    background-color: #ffffff;
    border: 1px solid rgba(23,23,23,0.1);
    width: 100%;
    margin-bottom: 20px;
    padding: 25px;
}

input.comment-box::placeholder {
    font-size: 15px;
    color: #575f66;
    font-weight: 300;
    font-family: "Archivo";
}

.comment-box:focus, #comment-msg-box:focus {
    border-color: #FF5E15;
    outline: 0;
    box-shadow: 0 0 6px rgb(204, 48, 0, 0.6);
}

/*form button*/
.comment-form .submit-comment {
    background: #FF5E15;
    border: 1px solid #FF5E15;
    padding: 14px 39px;
    margin-left: 15px;
    transition: .5s;
    font-size: 17px;
    color: #ffffff;
    font-weight: 500;
    font-family: "Yantramanav";
    margin-top: 10px;
    width: 25%;
}

.comment-form .submit-comment:hover {
    background: #fff;
    color: #FF5E15;
}

/*<!-- ============================================================== -->
<!-- Start .call-do-action Css -->
<!-- ============================================================== -->*/

.call-do-action {
    background: url(../../images/call-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 120px 0 65px;
    border-bottom: 1px solid #1F2437;
}

.call-do-action .section-title h1 {
    color: #fff;
}

.call-do-btn {
    margin-top: 45px;
    text-align: right;
}

.call-do-btn a {
    display: inline-block;
    font-size: 16px;
    text-transform: uppercase;
    padding: 12px 27px;
    color: #fff;
    position: relative;
    z-index: 1;
    border: 2px solid #5C5F70;
}

.call-do-btn a::before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #FF5D13;
    transform: scale(0);
    transition: .5s;
}

.call-do-btn a i {
    display: inline-block;
    font-size: 25px;
    transform: rotate(45deg);
    margin: 0 0 0;
    position: relative;
    bottom: -3px;
}

.call-do-btn a:hover {
    border: 2px solid #FF5D13;
}

.call-do-btn a:hover::before {
    transform: scale(1);
}

/**====================================================
<--  Prossess Ber Css -->
======================================================**/
.prossess-ber-plugin span {
    font-size: 18px;
    line-height: 28px;
    color: #0a1027;
    font-weight: 600;
    font-family: "Yantramanav";
}

.prossess-ber-plugin {
    margin-top: 45px;
}

.barfiller {
    width: 100%;
    height: 3px;
    position: relative;
    margin-bottom: 25px;
    margin-top: 12px;
    border-radius: 5px;
    background-color: #F2F2F2;
}

.barfiller .fill {
    display: block;
    position: relative;
    width: 0px;
    height: 100%;
    background: #333;
    z-index: 1;
}

.barfiller .tipWrap {
    display: none;
}

.barfiller .tip {
    font-size: 16px;
    left: 0px;
    border-radius: 2px;
    position: absolute;
    z-index: 2;
    top: -39px;
}

.stat-bar:nth-of-type(1) .stat-bar-rating {
    animation-delay: 0.25s;
    -webkit-animation-delay: 0.25s;
}

span.fill {
    background: #FF5E15!important;
    border-radius: 5px;
}

/*================================
 <--Start Team  Section Css-->
=================================*/

.team-area {
    background: #F2F3F5;
    padding: 120px 0 100px;
}

.team-area .section-title {
    text-align: left;
    margin-bottom: 12px;
}

.team-area .team-button {
    text-align: right;
    position: relative;
    top: -50%;
}

.team-area .team-button a {
    display: inline-block;
    font-size: 16px;
    background: #FF5E15;
    text-transform: uppercase;
    padding: 12px 22px;
    color: #fff;
    position: relative;
    z-index: 1;
}

.team-area .team-button a::before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #0A1028;
    transform: scale(0);
    transition: .5s;
}

.team-area .team-button a i {
    display: inline-block;
    font-size: 25px;
    transform: rotate(45deg);
    margin: 0 0 0;
    position: relative;
    bottom: -3px;
}

.team-area .team-button a:hover::before {
    transform: scale(1);
}

.team-area .single-team-box {
    margin-bottom: 30px;
    transition: .5s;
}

.team-area .single-team-thumb {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.team-area .single-team-thumb::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 0%;
    width: 100%;
    background: #0A1028;
    opacity: 0.4;
    transition: .5s;
}

.team-area .single-team-thumb img {
    width: 100%;
}

.team-area .team-icon-img {
    position: absolute;
    left: 0;
    bottom: 0;
    transition: .5s;
}

.team-area .team-icon-img img {
    width: inherit;
}

.team-area .team-icon-list {
    position: absolute;
    bottom: 0;
    left: -95px;
    z-index: 1;
    opacity: 0;
    transition: .5s;
}

.team-area .team-icon-list ul {
    display: inline-block;
    background: #fff;
    padding: 0 8px;
}

.team-area .team-icon-list ul li {
    display: inline-block;
    list-style: none;
    margin: 0 8px 0;
    color: #555555;
    transition: .5s;
    font-size: 17px;
}

.team-area .team-icon-list ul li a {
    display: inline-block;
    color: #555555;
    transition: .5s;
    font-size: 17px;
    padding: 13px 0;
    transition: .5s;
}

.team-area .team-title {
    text-align: center;
    margin: 30px 0 0;
}

.team-area .team-title h3 {
    font-size: 24px;
    line-height: 34px;
    color: #0a1027;
    font-weight: 500;
    margin: 0 0 0;
}

.team-area .team-title span {
    font-size: 17px;
    line-height: 30px;
    color: #ff5e14;
    font-weight: 400;
    font-family: "Archivo";
}

.team-area .team-icon-list ul li a i:hover{
    color: #FF5D13;
}

.team-area .single-team-box:hover .team-icon-list{
    left: 54px;
    opacity: 1;
}

.team-area .single-team-box:hover .single-team-thumb::before{
    height: 100%;
}

/*============================================
 <--Start .team-area.style_two  Css-->
==============================================*/

.team-area.style_two .section-title {
    text-align: center;
    margin-bottom: 60px;
}

/*===============================================
 <--Start brand-area  Css-->
=================================================*/

.brand-area {
    padding: 130px 0 130px;
}

.single-brand {
    text-align: center;
    background: #F2F3F5;
    padding: 30px 0;
    border-radius: 3px;
    transition: .5s;
    margin: 0 10px 0;
}

.brand-thumb img {
    opacity: 0.3;
    transition: .5s;
    display: inline-block!important;
}

.single-brand:hover .brand-thumb img{
     opacity: 1;
}

/*===============================================
 <--Start map-area  Css-->
=================================================*/

.map-area {
    background: #BFBFBF;
}

.row.ov-fl {
    overflow: hidden;
}



/*===============================================
 <--Start project  Css-->
=================================================*/

.project-area {
    padding: 120px 0 110px;
}

.project-area .section-title {
    text-align: center;
    margin-bottom: 50px;
}

.single-project-box {
    transition: .5s;
    margin: 0 12px 30px;
}

.project-area .project-thumb {
    position: relative;
    z-index: 1;
}

.project-thumb::before {
    position: absolute;
    content: "";
    top: 15px;
    left: 10px;
    border: 4px solid #fff;
    height: 0%;
    width: 95%;
    opacity: 0;
    transition: .5s;
}

.project-area .project-thumb img {
    width: 100%;
}

.project-area .project-title {
    padding: 35px 20px;
    position: absolute;
    bottom: 30px;
    left: 30px;
    background-image: linear-gradient(-90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9999999999999999) 100%);
}

.project-area .project-title h3 {
    font-size: 24px;
    line-height: 35px;
    color: #0a1027;
    font-weight: 700;
    margin: 0 0 0;
}

.project-area .project-title span {
    font-size: 16px;
    line-height: 27px;
    color: #ff5e14;
    font-weight: 400;
    font-family: "Archivo";
}

.single-project-box:hover .project-thumb::before{
    height: 94%;
    opacity: 1;
}

/**======================================================
<--  Start .project-area.style_two Css -->
======================================================**/

.project-area.style_two .section-title {
    text-align: left;
    margin-bottom: 10px;
}

.project-area.style_two .project_button {
    text-align: right;
    position: relative;
    top: -52%;
}

.project-area.style_two .project_button a {
    display: inline-block;
    font-size: 16px;
    background: #FF5E15;
    text-transform: uppercase;
    padding: 12px 22px;
    color: #fff;
    position: relative;
    z-index: 1;
}

.project-area.style_two .project_button a::before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #0A1028;
    transform: scale(0);
    transition: .5s;
}

.project-area.style_two .project_button a i {
    display: inline-block;
    font-size: 25px;
    transform: rotate(45deg);
    margin: 0 0 0;
    position: relative;
    bottom: -3px;
}

.project-area.style_two .project_button a:hover::before {
    transform: scale(1);
}



/**======================================================
<--  Start Footer Section Css -->
======================================================**/

.footer-section {
    padding: 100px 0px 205px;
    background: url(../../images/footer-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.post_blog_box {
    margin-bottom: 25px;
}

.footer_title h3 {
    opacity: 0.902;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    font-weight: 400;
    margin: 0 0 0;
}

.footer_title h4 {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    margin: 15px 0 0;
    line-height: 30px;
}

.footer_title h5 {
    opacity: 0.702;
    font-size: 24px;
    line-height: 20px;
    color: #ffffff;
    font-weight: 500;
    font-family: "Archivo";
    margin: 0 0 0;
}

/*widget title*/

h4.widget-title {
    margin: 0 0 40px;
    font-size: 22px;
    line-height: 28px;
    color: #ffffff;
    font-weight: 500;
}

.company-info-desc p {
    color: #fff;
    padding: 0 0 0px;
    font-size: 16px;
    margin: 35px 0 0;
}

/*social icon*/

.follow-company-icon a {
    font-size: 15px;
    display: inline-block;
    color: #0DC8AB;
    margin-right: 10px;
    transition: .5s;
    border: 1px solid #0DC8AB;
    height: 35px;
    width: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
}

.quick-link-button {
    margin-top: 26px;
}

.quick-link-button a {
    display: inline-block;
    font-size: 16px;
    background: #FF5E15;
    text-transform: uppercase;
    padding: 10px 27px;
    color: #fff;
    position: relative;
    z-index: 1;
}

.quick-link-button a::before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    transform: scale(0);
    transition: .5s;
}

.quick-link-button a i {
    display: inline-block;
    font-size: 25px;
    transform: rotate(45deg);
    margin: 0 0 0;
    position: relative;
    bottom: -3px;
}

.quick-link-button a:hover::before {
    transform: scale(1);
}

.quick-link-button a:hover {
    color: #FF5E15;
}

/*footer menu*/

.menu-quick-link-content {
    padding: 10px 0 0;
}


ul.footer-menu li {
    display: block;
    list-style: none;
}

ul.footer-menu li a {
    color: #fff;
    transition: .6s;
    font-size: 16px;
    position: relative;
    z-index: 1;
    padding: 0;
    font-weight: 400;
    text-decoration: none;
}

ul.footer-menu li a i {
    display: inline-block;
    margin-right: 5px;
}

.company-info p {
    font-size: 16px;
    color: #ffffff;
    font-weight: 400;
    line-height: 30px;
}

/*company contact info*/

.company-icon {
    float: left;
    margin-right: 10px;
}

.company-icon i {
    display: inline-block;
    font-size: 17px;
    transition: .5s;
    color: #fff;
    height: 40px;
    width: 40px;
    line-height: 40px;
    background: #0E3053;
    text-align: center;
    border-radius: 5px;
    margin: 10px 12px 0 0;
    transition: .5s;
}


.company-work-hour {
    transition: .5s;
}

.company-info p span {
    transition: .5s;
}

.company-work-hour:hover .company-icon i {
    color: #0CC8AD;
}

.company-work-hour:hover .company-info p span {
    color: #0CC8AD;
}

.company-icon i:hover {
    color: #fff!important;
    background: #E01142;
}

/*footer all hover*/

ul.footer-menu li a:hover {
    color: #FF5D13;
}

.follow-company-icon a:hover {
    border: 1px solid #fff;
    background: #fff;
}

/**footer bottom area**/

.row.footer-bottom {
    padding: 23px 0 22px;
    margin-top: 80px;
}

.footer-bottom-content-copy {
    text-align: center;
}

.footer-bottom-content-copy p {
    color: #FFf;
    margin: 0 0 5px;
    font-size: 15px;
}

.footer-bottom-content-copy span {
    color: #FFC704;
}

.footer-bottom-menu ul {
    text-align: right;
}

.footer-bottom-menu ul li {
    list-style: none;
    display: inline-block;
    padding-left: 42px;
}

.footer-bottom-menu ul li a {
    position: relative;
    z-index: 1;
    color: #fff;
    opacity: .8;
    text-decoration: none;
}

.footer-bottom-menu ul li a::before {
    position: absolute;
    content: "";
    left: -15px;
    top: 8px;
    width: 6px;
    height: 6px;
    background: #FBC012;
    border-radius: 6px;
}


/*Subscribe*/

.row.subscribe-section {
    /*background: url(../images/resource/subscrib.png);*/
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 55px 90px 55px;
    margin-bottom: 100px;
    border-radius: 10px;
}

span.subscribe-text {
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    font-weight: 400;
    font-family: "Rubik";
    overflow: hidden;
}

h2.subscribe-phone-number {
    font-size: 30px;
    line-height: 35px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Exo";
    margin: 0 0 6px;
}

h2.widget-title {
    font-size: 28px;
    color: #fff;
    text-align: center;
    padding-left: 12px;
}

.subscribe-widget form {
    position: relative;
    z-index: 1;
}

.subscribe-widget input {
    float: right;
    height: 56px;
    background-color: inherit;
    transition: .5s;
    border-radius: 5px;
    border: 1px solid #fff;
    padding: 7px 20px;
    display: block;
    width: 90%;
    color: #fff;
    font-weight: 300;
    font-size: 15px;
    position: relative;
    z-index: 1;
    outline: inherit;
}

.subscribe-widget input::placeholder {
    opacity: 0.502;
    font-size: 16px;
    line-height: 28px;
    color: #ffffff;
    font-weight: 400;
}

.subscribe-widget button {
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 0;
    padding: 14px 30px;
    border: 0;
    background: #fff;
    font-size: 16px;
    line-height: 28px;
    color: #012549;
    font-weight: 500;
    font-family: "Work Sans";
    border-radius: 0 5px 5px 0;
    transition: .5s;
}

.footer-shape {
    position: absolute;
    right: -230px;
    text-align: right;
    bottom: -97px;
}

.footer-shape2 {
    position: absolute;
    top: -70px;
    left: -180px;
}

.subscribe-icon {
    float: left;
    margin-right: 30px;
}

.subscribe-icon i {
    display: inline-block;
    font-size: 70px;
}


/*<!-- ============================================================== -->
<!-- hendre Scrollup Section -->
<!-- ============================================================== -->*/

.copyright-section {
    padding: 35px 0;
    margin-top: -97px;
    background-color: #040a21;
    opacity: 0.600;
}

.copyright-icon ul li {
    display: inline-block;
    margin-right: 15px;
}

.copyright-icon ul li a {
    display: inline-block;
    color: #fff;
    transition: .5s;
    font-size: 16px;
}

.rights_site {
    text-align: right;
}

.rights_site h4 {
    font-size: 16px;
    line-height: 28px;
    color: #ffffff;
    font-weight: 400;
    margin: 0 0 0;
}

.rights_site h4 span{
    color: #E01142;
}

.copyright-icon ul li a:hover {
    color: #FF5D13;
}


/*================================
 <--Polytia   Blgo Section style2 Css-->
==================================*/
span.tag {
    display: inline-block;
    font-size: 14px;
    line-height: 1.72;
    font-weight: 400;
    color: #fff;
    background: #E01142;
    padding: 3px 10px;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    top: 20px;
}

.blog-contents.style {
    padding: 34px 20px 32px 35px;
}

.blog-btn.style_bg a {
    display: inline-block;
    color: #012549;
    border-bottom: 1px solid #0125493b;
}


.blog-btn.style_bg a:hover{
    color: #E01142;
    border-bottom: 1px solid #E01142;
}

.blog-btn.style_bg a i {
    font-size: 20px;
    position: relative;
    top: 3px;
    margin-left: 7px;
    display: inline-block;
    transform: rotate(-45deg);
}


/*<!-- ============================================================== -->
<!-- Start   Polytia Contact Area Css -->
<!-- ============================================================== -->*/
.contact-section {
    padding: 110px 0 70px;
}

.contact-left {
    position: relative;
    margin-left: 36px;
}

.row.contact_bg {
    padding: 43px 23px 50px;
    background: #fff;
    box-shadow: 0px 5px 15px rgb(88 82 174 / 10%);
    position: relative;
}

.row.contact_bg p {
    color: #1d263a;
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: 700;
}

.form_box input {
    border: 1px solid rgba(100,100,100,0.1);
    width: 100%;
    height: 50px;
    padding: 0 18px;
    margin-bottom: 20px;
    outline: 0;
}

.form_box input::placeholder {
    color: rgba(100,100,100,0.8);
}

.form_box textarea {
    width: 100%;
    height: 135px;
    padding: 14px 18px;
    border:  1px solid rgba(100,100,100,0.1);
    outline: 0;
}

.form_box textarea::placeholder {
    color: rgba(100,100,100,0.8);
}

.contact-form-button button {
    background: #E11143;
    color: #fff;
    border: 1px solid #E11143;
    padding: 11px 33px;
    margin-top: 35px;
    position: relative;
    z-index: 1;
    transition: .5s;
}

.contact-form-button button:hover {
    color: #E11143;
}

.contact-form-button button::before {
    position: absolute;
    top: 0;
    right: 0;
    content: "";
    background: #fff;
    height: 100%;
    transition: .5s;
    width: 0;
    z-index: -1;
}

.contact-form-button button:hover::before {
    width: 100%;
    left: 0;
}

.contact-form-button button i {
    margin-left: 10px;
}

/* google section  */

.mapouter.fixed-height {
    height: 600px;
}

.gmap_canvas {
    width: 100%;
    height: 100%;
}
.mapouter iframe {
    height: 92%;
    width: 100%;
}


/*
<!-- ============================================================== -->
<!-- Constre Scrollup Section -->
<!-- ============================================================== -->*/

/*------back-to-top------------*/
.prgoress_indicator {
    position: fixed;
    right: 50px;
    bottom: 75px;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    border-radius: 50px;
    box-shadow: inset 0 0 0 2px rgba(22, 93, 245, 0.2);
    z-index: 10000;
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
    transform: translateY(15px) !important;
    transition: all 200ms linear !important;
  }
  .prgoress_indicator.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0) !important;
  }

  .prgoress_indicator::after {
    position: absolute;
    content: "\f106";
    font-family: "Font Awesome 5 Free";
    text-align: center;
    line-height: 46px;
    font-size: 18px;
    color: #FF5D13;
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    font-weight: 900;
    z-index: 1;
    transition: all 200ms linear !important;
}

.prgoress_indicator::before {
    position: absolute;
    content: "\f633";
    font-family: "Font Awesome 5 Free";
    text-align: center;
    line-height: 46px;
    font-size: 18px;
    font-weight: 900;
    opacity: 0;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    left: 0;
    font-family: bootstrap-icons !important;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    z-index: 2;
    transition: all 200ms linear !important;
}
  .prgoress_indicator:hover ::after {
    color: var(--primary-color-one);
  }
  .prgoress_indicator:hover ::before {
    opacity: 1;
  }
  .prgoress_indicator svg path {
    fill: none;
  }
  .prgoress_indicator svg.progress-circle path {
    stroke: #FF5E15;
    stroke-width: 4;
    box-sizing: border-box;
    transition: all 200ms linear !important;
}


/*
<!-- ============================================================== -->
<!-- hendre Bounce Animation Css -->
<!-- ============================================================== -->*/
/* bounce-animate */
.bounce-animate {
    animation-name: float-bob;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob;
    -moz-animation-duration: 2s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob;
    -ms-animation-duration: 2s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob;
    -o-animation-duration: 2s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

.bounce-animate2 {
    animation-name: float-bob2;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob2;
    -moz-animation-duration: 3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob2;
    -ms-animation-duration: 3s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob2;
    -o-animation-duration: 3s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

.bounce-animate3 {
    animation-name: float-bob3;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob3;
    -moz-animation-duration: 3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob3;
    -ms-animation-duration: 3s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob3;
    -o-animation-duration: 3s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

.bounce-animate4 {
    animation-name: float-bob4;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob4;
    -moz-animation-duration: 5s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob4;
    -ms-animation-duration: 5s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob4;
    -o-animation-duration: 5s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

.bounce-animate5 {
    animation-name: float-bob5;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob5;
    -moz-animation-duration: 6s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob5;
    -ms-animation-duration: 6s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob5;
    -o-animation-duration: 6s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

.bounce-animate-slow {
    animation-name: float-bob5;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob5;
    -moz-animation-duration: 15s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob5;
    -ms-animation-duration: 15s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob5;
    -o-animation-duration: 15s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

.bounce-animate-3 {
    animation-name: float-bob6;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob6;
    -moz-animation-duration: 6s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob6;
    -ms-animation-duration: 6s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob6;
    -o-animation-duration: 6s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

@keyframes float-bob {
    0% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    50% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }

    100% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
}

@keyframes float-bob2 {
    0% {
        -webkit-transform: translateY(-60px);
        transform: translateY(-60px);
    }

    50% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    100% {
        -webkit-transform: translateY(-60px);
        transform: translateY(-60px);
    }
}

@keyframes float-bob3 {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
    }

    50% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }

    100% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
    }
}

@keyframes float-bob4 {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
    }

    50% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }

    100% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
    }
}

@keyframes float-bob5 {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
    }

    50% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }

    100% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
    }
}

@keyframes float-bob6 {
    0% {
        -webkit-transform: translateY(-40px);
        transform: translateY(-40px);
    }

    50% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }

    100% {
        -webkit-transform: translateY(-40px);
        transform: translateY(-40px);
    }
}

@keyframes movebounce {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }

    50% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}


@keyframes moveleftbounce {
    0% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }

    50% {
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}



/*--==============================================->
  <!-- hendre Animation Dance -->
 ==================================================-*/
/**/
.dance3 {
    -webkit-animation: dance3 8s alternate infinite;
    animation: dance3 8s alternate infinite;
}

@keyframes dance3 {
    0% {
        -webkit-transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
    }
}

.dance {
    -webkit-animation: dance 2s alternate infinite;
    animation: dance 2s alternate infinite;
}

@keyframes dance {
    0% {
        -webkit-transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1.2);
    }
}

.dance2 {
    -webkit-animation: dance2 4s alternate infinite;
    animation: dance2 4s alternate infinite;
}

@keyframes dance2 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
    }

    50% {
        -webkit-transform: translate3d(25px, -25px, 0);
    }

    100% {
        -webkit-transform: translate3d(0, -25px, 25px);
    }
}

.watermark-animate {
    -webkit-animation: watermark-animate 8s infinite;
    animation: watermark-animate 8s infinite;
    animation-direction: alternate-reverse;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@keyframes watermark-animate {
    0% {
        left: 0;
    }

    100% {
        left: 100%;
    }
}





/*rotateme animation*/
.rotateme {
    -webkit-animation-name: rotateme;
    animation-name: rotateme;
    -webkit-animation-duration: 20s;
    animation-duration: 20s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@keyframes rotateme {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/*** 
====================================================================
  Search Popup
====================================================================
***/
.search-popup{
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 99999;
  margin-top: -540px;
  transform: translateY(-100%);
  background-color: rgba(0,0,0,0.90);
    -webkit-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition: all 1500ms cubic-bezier(0.860, 0.000, 0.070, 1.000); /* easeInOutQuint */
    -webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000); /* easeInOutQuint */
}

.search-popup{
  width: 100%;
}
.search-active .search-popup{
  transform: translateY(0%);
  margin-top: 0;
}

.search-box-btn.search-box-outer {
    display: inline-block;
    top: 33px;
}

.search-box-btn.search-box-outer i {
    display: inline-block;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    color: #fff;
    margin-top: 0px;
    margin-left: 3px;
    font-weight: 600;
}

.sticky .search-box-btn.search-box-outer i {
    color: #fff;
}

.hendre_nav_manu.style_two .search-box-btn.search-box-outer i {
    color: #333!important;
}

.sticky-nav .search-box-btn.search-box-outer {
    display: inline-block;
    position: absolute;
    right: -50px;
    top: 33px;
}
.sticky-nav .search-box-btn.search-box-outer i {
    color: #fff;
    position: relative;
    left: 40px;
}
.search-popup .close-search {
    position: absolute;
    left: 0;
    right: 0;
    top: 75%;
    margin: 0 auto;
    margin-top: -200px;
    border-radius: 50%;
    text-align: center;
    background-color: #FF5E15;
    width: 70px;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    border-bottom: 3px solid #ffffff;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    opacity: 0;
    visibility: hidden;
    height: 70px;
    line-height: 70px;
    text-align: center;
}
.search-popup .close-search i {
    position: relative;
    height: 70px;
    width: 70px;
    font-size: 30px;
    line-height: 70px;
    color: #fff;
    display: inline;
    text-align: center;
}

.search-active .search-popup .close-search{
  visibility: visible;
  opacity: 1;
  top: 50%;
  -webkit-transition-delay: 1500ms;
  -moz-transition-delay: 1500ms;
  -ms-transition-delay: 1500ms;
  -o-transition-delay: 1500ms;
  transition-delay: 1500ms;
}

.search-popup form{
  position: absolute;
  max-width: 700px;
  top: 50%;
  left: 15px;
  right: 15px;
  margin:-35px auto 0;
  transform: scaleX(0);
  transform-origin: center;
  background-color: #111111;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.search-active .search-popup form{
  transform: scaleX(1);
  -webkit-transition-delay: 1200ms;
  -moz-transition-delay: 1200ms;
  -ms-transition-delay: 1200ms;
  -o-transition-delay: 1200ms;
  transition-delay: 1200ms;
}

.search-popup .form-group{
  position:relative;
  margin:0px; 
  overflow: hidden;
}
.search-popup .close-search.style-two{
  position: absolute;
  right: 25px;
  left: auto;
  color:#ffffff;
  width:auto;
  height:auto;
  top:25px;
  margin:0px;
  border:none;
  background:none !important;
  box-shadow:none !important;
  -webkit-transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
  transition:all 500ms ease;
}

.search-popup .close-search.style-two i {
    font-size: 20px;
    color: #fff;
}
.search-popup .form-group input[type="text"],
.search-popup .form-group input[type="search"]{
  position:relative;
  display:block;
  font-size:18px;
  line-height: 50px;
  color:#000000;
  height:70px;
  width:100%;
  padding: 10px 30px;
  background-color: #ffffff;
  -webkit-transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
  transition:all 500ms ease;
  font-weight:500;
  text-transform:capitalize;
}
.search-popup .form-group input[type="submit"], .search-popup .form-group button {
    position: absolute;
    right: 30px;
    top: 0px;
    height: 70px;
    line-height: 70px;
    background: transparent;
    text-align: center;
    font-size: 24px;
    color: #000000;
    padding: 0;
    cursor: pointer;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    border: none;
}

.search-popup .form-group input[type="submit"]:hover,
.search-popup .form-group button:hover{
  color: #000000;
}

.search-popup input::placeholder,
.search-popup textarea::placeholder{
  color:#000000;
}



/*===========================
<-- dexon Loader Css -->
=============================*/
.loader-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: 0.8s 1s ease;
    z-index: 999;
}

.loader {
    position: relative;
    display: block;
    z-index: 201;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    margin: -50px 0 0 -50px;
    border-radius: 50%;
    transition: all 1s 1s ease;
    border: 3px solid #FF1493;
    border-top-color: #FFFF00;
    -webkit-animation: spin 1.5s linear infinite;
    -moz-animation: spin 1.5s linear infinite;
    -o-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}

.loader::before {
    position: absolute;
    content: '';
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    border-radius: 50%;
    border: 3px solid #FF5E15;
    border-left-color: #663399;
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    -o-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.loader::after {
    position: absolute;
    content: '';
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid #FF5E15;
    border-right-color: #00FF00;
    -webkit-animation: spin 2.5s linear infinite;
    -moz-animation: spin 2.5s linear infinite;
    -o-animation: spin 2.5s linear infinite;
    animation: spin 2.5s linear infinite;
}

/*/ Here comes the Magic /*/

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loader-wrapper .loder-section {
    position: fixed;
    top: 0;
    width: 50%;
    height: 100%;
    background: #111;
    z-index: 2;
}

.loader-wrapper .loder-section.left-section {
    left: 0;
    transition: 1s 1.4s ease;
}

.loader-wrapper .loder-section.right-section {
    right: 0;
    transition: 1s 1.4s ease;
}

/*/ When page loaded /*/

.loaded .loder-section.left-section {
    left: -100%;
}

.loaded .loder-section.right-section {
    right: -100%;
}

.loaded .loader-wrapper {
    visibility: hidden;
}

.loaded .loader {
    top: -100%;
    opacity: 0;
}




/*curser point */

.curser {
    position: fixed;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    border: 2px solid #FF5E15;
    border-radius: 50%;
    pointer-events: none;
    transform: translate(-50%, -50%);
    z-index: 9999;
    transition: .1s;
}

.curser2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    background: #FF5E15;
    border-radius: 50%;
    pointer-events: none;
    transform: translate(-50%, -50%);
    z-index: 9999;
    transition: .15s;
    animation: Ripple 1.6s ease-in-out infinite;
    -webkit-animation: Ripple 1.6s ease-in-out infinite;
    -moz-animation: Ripple 1.6s ease-in-out infinite;
}
