/*shades of red*/
$red-primary: #ff0000;
$red-secondary: #ed1c24;

/*shades of white*/
$white: #ffffff;

/*shades of black*/
$black: #000000;
$black-second: #231f20;

/*shades of blue*/
$blue-primary: #004c97;
$blue-second: #275a9b;

/*shades of gray*/
$gray-primary: #a0a3a6;
$gray-secondary: #f6f6f6;
$gray-third: #d0d4d9;
$gray-fourth: #757575;
$gray-fifth: #dfdcdc;
$gray-sixth: #b7b7b7;
$gray-seventh: #e4e6e7;
$gray-eighth: #212529;

/***======================================================
<-- Constre Why Choose Area Css -->
======================================================***/

root {
  --w: 20px;
}

.hendre_nav_manu {
  background: #F5F6F8;
}

.sticky {
  background: #F5F6F8 !important;
}

.footer-section {
  background: #F5F6F8 !important;
}

.copyright-section {
  opacity: unset;
}

.hendre_menu > ul > li > a, .sticky .hendre_menu li a {
  /* color: #1774BC;*/
  color: #000;
}

.hendre_menu > ul > li > a:hover {
  color: #ee1c25 !important;
}

.quick-link-button a::before, .constre-btn a:hover {
  background: #000;
  color: #fff;
}

.slider-title {
  color: #fff;
}

.slider-area .slider-content .slider-title h4 {
  color: #000 !important;
}

.mean-container .mean-bar {
  background: #F5F6F8;
}

.mean-container .mean-bar:before {
  color: #000;
  /*content: "";*/
  /*background: url(../images/logo_poland.png);*/
  content: url(../images/logo_poland.png);
}

.mobile-menu-area a.active {
  color: #F00 !important;
}

.mean-container a.meanmenu-reveal span, .mean-container a.meanmenu-reveal span {
  background: #000;
}

.footer-section .container p,
h4.widget-title,
.footer_title h3,
.footer_title h4,
.footer_title h5,
.constre-btn a:hover {
  color: #000 !important;
}

.header-button a, .about-btn a, .quick-link-button a, .contact-us button.btn, .choose-btn a:after, .constre-btn a {
  background: #ee1c25;
}

.owl-prev:hover, .owl-next:hover {
  background: #ee1c25 !important;
}

a.mailto {
  color: #000 !important;
}

a.mailto:hover {
  color: #ee1c25 !important;
}

a:hover {
  color: #fff !important;
}

.header-button a::before, .sticky .header-button a::before {
  background: #000;
}

.section-title h4, .product-single__name {
  font-weight: 500;
  font-family: "Yantramanav";
  font-size: 18px;
  color: #ff5e14;
  position: relative;
  z-index: 1;
  display: inline-block;
  line-height: 1.1;
}

.section-title h4:before, .product-single__name:before {
  position: absolute;
  content: "";
  top: 8px;
  left: 0;
  width: 35px;
  height: 2px;
  background-color: #ff5e14;
  z-index: -1;
}

.why-choose-us-area {
  background: #F5F6F8;
  position: relative;
  padding: 20px 0 40px;

  .why-choose-us-area.style-two {
    padding: 50px 0 0px;
  }

  .row.choose-us_bg {
    position: relative;
    z-index: 1;
    padding: 84px 0 97px;
  }

  .row.choose-us_bg:before {
    position: absolute;
    content: "";
    left: -292px;
    top: 0;
    height: 100%;
    width: 121%;
    background: url(../images/choose_bg.jpg);
    z-index: -1;
    background-repeat: no-repeat;
  }

  .section-title {
    margin-bottom: 47px;
  }

  .section-title h4 {
    margin-bottom: 7px;
    padding-left: 45px;
  }

  .section-title p.section-description {
    font-size: 16px;
    line-height: 26px;
    color: #575f66;
    font-weight: 300;
    font-family: "Archivo";
    width: 88%;
    margin: 0;
  }

  .choose-list ul li img {
    position: relative;
    margin-right: 12px;
    margin-top: -2px;
  }

  .choose-list ul li {
    font-size: 18px;
    color: #171717;
    font-weight: 400;
    font-family: "Yantramanav";
    margin-bottom: 10px;
    list-style: none;
  }

  .about-btn {
    margin-top: 40px;
  }

}

@media (max-width: 1400px) {
  .why-choose-us-area {
    .row.choose-us_bg:before {
      background: none;
    }
  }
}

.section-title h1 {
  font-size: 48px;
  color: #171717;
  font-weight: 700;
  font-family: "Yantramanav";
  margin-bottom: 20px;
}

.section-title.text-center {
  margin-bottom: 57px;
}

.section-title.style1.pb_20 {
  margin-bottom: 37px;
}

.section-title.style1.text-center {
  margin-bottom: 37px;
}

.section-title.style1.text-center {
  margin-bottom: 37px;
}

.section-title.style1 h1 {
  color: #ffffff;
  font-family: "Yantramanav";
}

.section-title.style1.pb-10 {
  margin-bottom: 23px;
}

.section-title.style-2 {
  margin-bottom: 42px;
}

.section-title.style-2.pb-10 {
  margin-bottom: 10px;
}

/**
======================================================
<--  Constre Section Title Css -->
======================================================**/

/* choose btn */
.choose-btn {
  margin-top: 40px;
}

.choose-btn a {
  display: inline-block;
  font-size: 17px;
  color: #ffffff;
  font-weight: 500;
  font-family: "Yantramanav";
  background: #171717;
  padding: 15px 33px;
  position: relative;
  z-index: 1;
}

.choose-btn a:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 0%;
  /*background: #FF5E15;*/
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.5s;
  transform: scale(0);
  opacity: 0;
}

.choose-btn a:hover:after {
  transform: scale(1);
  opacity: 1;
  height: 100%;
  width: 100%;
}

.choose-btn a i {
  font-size: 13px;
  font-weight: 600;
  margin-left: 12px;
  display: inline-block;
  transform: rotate(-45deg);
}

/* ovl nav */

.owl-nav {
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  top: 80px;
  z-index: 1;
}

.owl-nav:before {
  position: absolute;
  content: "";
  left: 43%;
  top: 26px;
  width: 50px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.10196078431372549);
  z-index: -1;
  margin: auto;
}

.owl-nav:after {
  position: absolute;
  content: "";
  right: 43%;
  top: 26px;
  width: 50px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.10196078431372549);
  z-index: -1;
  margin: auto;
}

.owl-prev {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: #2F2F2F;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  margin-right: 15px;
  transition: .5s;
  color: #fff;
}

.owl-next {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: #2F2F2F;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  transition: .5s;
  color: #fff;
}

.owl-prev:hover {
  background: #FF5E15;
  color: #fff;
}

.owl-next:hover {
  background: #FF5E15;
  color: #fff;
}

/* ovl nav */

/* choose btn */

.case-study-area {
  background: #171717;
  padding: 108px 0 200px;

  /*case thumb*/
  .case-study-thumb {
    position: relative;
    z-index: 1;
  }

  .case-study-thumb img {
    width: 100%;
  }

  .case-study-content {
    position: absolute;
    bottom: -50px;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.9999999999999999) 0%, rgba(255, 255, 255, 0.9999999999999999) 9%, rgba(255, 255, 255, 0) 100%);
    border: 2px solid rgba(255, 255, 255, 0.7);
    width: 82%;
    padding: 25px 0 29px;
    text-align: left;
    border-left: 3px solid #FF5E15;
    padding-left: 35px;
    opacity: 0;
    left: 26px;
    transition: .5s;
  }

  .case-study-title h3 {
    margin-top: 5px;
  }

  .case-study-title h3 a {
    font-size: 28px;
    color: #171717;
    font-weight: 500;
    font-family: "Yantramanav";
    display: inline-block;
  }

  .case-title span a {
    display: inline-block;
    font-size: 18px;
    color: #ff5e14;
    font-weight: 500;
    font-family: "Yantramanav";
  }


  .case-text {
    float: left;
  }


  .case-right-btn {
    text-align: right;
    padding-right: 30px;
    position: relative;
    margin-top: 10px;
  }

  .case-right-btn a {
    text-align: center;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-color: #ff5e14;
    line-height: 50px;
  }

  .case-right-btn a i {
    color: #fff;
    font-size: 16px;
    transform: rotate(-45deg);
    display: inline-block;
  }

  .owl-item.active.center .case-study-thumb img {
    transform: scale(1.1);
  }


  .owl-carousel .owl-item img {
    display: inline-block;
    padding: 20px 30px 20px;
  }


  .owl-item.active.center .case-study-content {
    opacity: 1;
    bottom: 42px;
  }


  .section-title h1 {
    margin-top: 11px;
    line-height: 1.1;
  }

  .section-title h4 {
    margin-top: 11px;
    margin-bottom: 7px;
    padding-left: 45px;
  }

  .owl-carousel .owl-item img {
    display: inline-block;
    padding: 20px 30px 20px;
  }

}

.case-study-thumb2 {
  border: 1px solid #dbdbdb;
}

/* counter upper */

.row.counter_upper {
  margin-top: 40px;
}

.counter-single-item {
  background: url(../images/counter_shape.png);
  padding: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 30px;
}

.counter-single-item:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  border-bottom: 15px solid #FF5E15;
  border-left: 15px solid #FF5E15;
  border-top: 15px solid #FF5E15;
  border-right: 15px solid #FF5E15;
  height: 100%;
  width: 100%;
  transition: .5s;
  opacity: 0;
}

.counter-single-item:hover:before {
  opacity: 1;
}

.counter-single-item:after {
  position: absolute;
  content: "";
  right: -84%;
  background: url(../images/feature_shape.png);
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  bottom: -75%;
  opacity: 0;
  transition: .5s;
}

.counter-single-item:hover:after {
  opacity: 1;
}

.counter-content {
  text-align: center;
  background: #fff;
  padding: 19px 0 36px;
}

.counter-title h1 {
  display: inline-block;
  font-size: 48px;
  color: #171717;
  font-weight: 700;
  font-family: "Yantramanav";
  text-align: center;
}

.counter-title span {
  font-size: 48px;
  color: #171717;
  font-weight: 700;
  font-family: "Yantramanav";
}

.counter-title h5 {
  font-size: 16px;
  color: #575f66;
  font-weight: 300;
  font-family: "Archivo";
}

.chosse-shpe {
  position: absolute;
  bottom: 0;
  right: 0;
  animation: dance3 4s alternate infinite;
}

.testimonial-area {
  background: #0A1028 !important;

  .section-title h4:before {
    display: none;
  }

}

.testi_list {

  .testimonial-description {
    padding: 0 5%;
  }
}

.testi_list.owl-carousel {

  .owl-nav {
    top: 0;
  }

  .owl-nav:before {
    left: 30%;
  }

  .owl-nav:after {
    right: 30%;
  }

  .owl-stage-outer {
    margin-bottom: 30px;
  }

}

.relation_list {
  .single-service-thumb {
    background-color: #FFF;
    padding-top: 3%;
  }

  .service-content {
    background: #fff;
    padding: 25px 23px 23px;
    position: relative;
    z-index: 1;
  }

  .service-content a {
    display: inline-block;
    font-size: 22px;
    color: #0a1027;
    font-weight: 700;
    transition: 0.5s;
  }

  .service-content:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 5px;
    left: 0;
    top: 0;
    background: #ff5e14;
    z-index: -1;
  }

  .service-content p {
    margin: 16px 0 25px;
  }


  .single-service-box {
    position: relative;
  }

  .single-service-thumb img {
    width: 100%;
    transition: 1s;
  }

  .single-service-box:hover .service-content a {
    color: #ff5e14;
  }

  .single-service-box:hover .single-service-thumb img {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }


}

.relation_list.owl-carousel {

  .owl-nav {
    top: 38px;
  }

  .owl-nav:before {
    left: 30%;
  }

  .owl-nav:after {
    right: 30%;
  }

}

.single-service-thumb img.example {
  /*width: 772px;
  height: 250px;*/
  /* left: -3px;*/
}


/*.footer-section {
  background: #0A1028 !important;
}*/

.em-bar {
  position: relative;
  background: #D0D0D0;
  width: 100%;
  height: 1px;
  left: 0;
  top: -81px;
}

.em-bar:before {
  position: absolute;
  content: "";
  background: #ff5e14;
  width: 20px;
  height: 2px;
  left: 0;
  top: -1px;
  transition: 1s;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOV-BG;
}

@-webkit-keyframes MOV-BG {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100vw);
  }
}

@media (max-width: 768px) {

  .why-choose-us-area {
    padding: 0;
  }

  .owl-nav {
    top: 20px;
  }

  .case-study-area {
    padding-bottom: 100px;
  }

  .relation_list.owl-carousel {
    margin-top: 30px;
  }

  .relation_list.owl-carousel, .testi_list.owl-carousel {
    .owl-nav:before {
      left: 25%;
    }

    .owl-nav:after {
      right: 25%;
    }
  }

  .case-study.owl-carousel {
    .owl-nav:before {
      left: 31%;
    }

    .owl-nav:after {
      right: 31%;
    }
  }

}

@media (max-width: 500px) {

  .relation_list.owl-carousel {
    margin-top: 30px;
  }

  .relation_list.owl-carousel, .testi_list.owl-carousel, .case-study-who-we-are-benefits-list.owl-carousel {
    .owl-nav:before {
      left: 18%;
    }

    .owl-nav:after {
      right: 18%;
    }
  }

  .case-study.owl-carousel {
    .owl-nav:before {
      left: 20%;
    }

    .owl-nav:after {
      right: 20%;
    }
  }

}

.case-study-who-we-are {
  .row.counter_upper {
    margin-top: 0;
  }

  .counter-title h1 {
    font-size: 30px;
  }

  .counter-title span {
    font-size: 30px;
  }

  .row.counter_upper {
    background: url(../images/counter_shape.png);

    .col-lg-6 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .counter-single-item {
    /*background: url(../images/counter_shape.png);*/
    background: none;
    padding: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-bottom: 0;
  }

  .counter-content {
    text-align: center;
    background: #fff;
    padding: 10px 0;
  }

  .csd-info-sidber-box {
    padding: 0 40px 30px;
  }
}

.case-study-who-we-are.case-study-details {
  padding: 0;
}

.case-study-who-we-are.team-area {
  padding: 0;
}

.case-study-who-we-are-benefits-list {
  .service-details-item {
    position: relative;
    margin-bottom: 30px;
    padding: 0 16px 131px;
  }

  .owl-nav {
    top: 45px;
  }

  .owl-prev, .owl-next {
    background-color: #FF5E15;
  }

  .owl-nav:before {
    background-color: #FF5E15;
    left: 40%;
  }

  .owl-nav:after {
    background-color: #FF5E15;
    right: 40%;
  }

  .owl-prev:hover {
    background: #ffa814;
    color: #fff;
  }

  .owl-next:hover {
    background: #ffa814;
    color: #fff;
  }

}

.contact-us {
  .section-title h4:before {
    display: none;
  }
}

.header-button a.active {
  color: #ee1c25;
  background: #fff;
}

.loader {
  border: 3px solid #ff0000;
  border-top-color: #0186CF;
}

.loader::before {
  border: 3px solid #fff;
  border-left-color: #1212e8;
}

.loader::after {
  border: 3px solid #0186CF;
  border-right-color: #F5F6F8;
}

.case-study-single-box .case-study-title-on-photo {
  opacity: 1;
  transition: all .5s ease-in-out;
}

.case-study-single-box:hover .case-study-title-on-photo {
  visibility: hidden;
  opacity: 0;
  transition: all .5s ease-in-out;
}

.case-study-title-on-photo {
  display: inline-block;
  font-size: 18px;
  color: #ff5e14;
  font-weight: 400;
  font-family: "Yantramanav";
  position: absolute;
  z-index: 10;
  padding: 10px 10px;
}

.case-study-title.style2 h3 {
  font-size: 20px;
}

/*
Owl products carousel
*/
.case-study-title-on-photo2 {
  opacity: 1;
  transition: all .5s ease-in-out;
  font-size: 18px;
  color: #ff5e14;
  font-weight: 400;
  font-family: "Yantramanav";
  position: absolute;
  z-index: 10;
  padding-top: 24px;
  padding-left: 40px;
}

.owl-item.active.center .case-study-title-on-photo2 {
  visibility: hidden;
  opacity: 0;
  transition: all .5s ease-in-out;
}

.product-single .constre-btn {
  text-align: left;

  a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

}

.polityka-prywatnosci .case-sidber {
  margin-top: 0;
}

#flash-messages {
  position: absolute;
  z-index: 30;
  margin: 0 auto;
  width: 100%;

  .alert {
    position: relative;
    width: 60%;
    margin: 0 auto;
  }
}

#overlay {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: none;
  background: rgba(0, 0, 0, 0.6);
}

.cv-spinner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px #ddd solid;
  border-top: 4px #2e93e6 solid;
  border-radius: 50%;
  animation: sp-anime 0.8s infinite linear;
}

@keyframes sp-anime {
  100% {
    transform: rotate(360deg);
  }
}

.is-hide {
  display: none;
}